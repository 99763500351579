page-title .title-container {
	position: fixed;
	width: 100%;
	top: 64px;
	padding-left: 80px;
	padding-right: 80px;
	z-index: 3;
	@include transition(padding-right);
	@include transition-duration(300ms);
	background-color: $cds-color-background-neutral-light;

	@media(max-width: $cds-size-breakpoint-lg) {
		padding-right: 20px;
		padding-left: 20px;
	}

	.content {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		height: 68px;

		.title {
			background-color: transparent;
			display: flex;
			align-items: center;

			span {
				color: $cds-color-text-neutral-medium-emphasis;
				@include typography(headline-6);
			}

			button {
				margin-right: 16px;
			}
		}

		.actions-container {
			display: flex;
			align-items: center;
			justify-content: space-between;
			column-gap: 16px;

			button[mat-icon-button] {
				.mat-icon {
					color: $cds-color-action-neutral-inactive;
				}
			}

			[data-counter]:after {
				content: attr(data-counter);
				position: absolute;
				color: $cds-color-action-white-active;
				font-style: normal;
				background: var(--theme-primary-400);
				padding: 1px 4px;
				border-radius: 2px;
				top: -6px;
				left: 24px;
				font-size: 12px;
				line-height: 16px;
			}
		}

		button[mat-icon-button] {
			line-height: 32px;
			padding: 0;
			display: flex;
			text-align: center;

			.mat-icon {
				font-size: 32px !important;
				line-height: 32px !important;
				height: 32px !important;
				width: 32px !important;
			}
		}
	}
}

body.menu-opened {
	.title-container {
		padding-right: 380px;

		@media (min-width: $screen-lg) and (max-width: $cds-size-breakpoint-lg) {
			padding-right: 20px;
		}

		@media (max-width: $screen-lg) {
			padding-right: 330px;
		}
	}
}