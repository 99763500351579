@keyframes searching {
	0% {
		background-color: rgba(var(--theme-primary-700_rgb),.2);
	}

	100% {
		background-color: transparent;
	}
}

.advanced-search {
	display: flex;
	flex-direction: column;

	.alerte {
		display: flex;
		flex-direction: column;
		padding: 16px 0px;
		border-bottom: 1px solid $cds-color-border-neutral-decorative;
		color: $cds-color-text-neutral-medium-emphasis;
		text-align: center;
		@include typography(body-1);

		.result-counter {
			color: $cds-color-text-neutral-medium-emphasis;
			@include typography(headline-6);

			.counter {
				color: $cds-color-text-primary-high-emphasis;
			}
		}

		&.searching {
			animation: searching 200ms linear;
		}
	}

	.liste-sections {
		display: flex;
		flex-direction: column;

		.section {
			display: flex;
			flex-direction: column;
			border-bottom: 1px solid $cds-color-border-neutral-decorative;
			color: $cds-color-text-neutral-medium-emphasis;
			cursor: pointer;
			@include typography(body-1);

			.header {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				padding: 12px 16px;

				.end {
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: flex-end;

					icon {
						color: $cds-color-text-neutral-medium-emphasis;
					}

					.badge {
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						width: 20px;
						height: 20px;
						margin-right: 10px;
						border-radius: 50%;
						background-color: $cds-color-text-primary-high-emphasis;
						cursor: pointer;
					}
				}
			}

			.row.collapse {
				padding: 0 16px;
			}

			.content {
				padding: 0 16px;

				.boolean-button {
					font-size: 13px;

					mat-button-toggle-group {
						width: 50%;
						flex: 2;

						mat-button-toggle {
							flex: 1;
						}
					}
				}

				.optional-button {
					margin-bottom: 16px;
					flex: 1;
					font-size: 13px;

					mat-button-toggle {
						flex: 1;
					}
				}
			}
		}
	}
}