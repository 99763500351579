.etat-lieux {
	.el-container {
		display: flex;
		justify-content: center;
	}

	.el-vehicule-container {
		width: 300px;
		height: 609px;
		background-image: url('../../../assets/layout/vehicule/etatLieux.png');
		background-repeat: no-repeat;
		background-size: cover;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.el-box-container {
		width: 429px;
		height: 122px;
		margin-bottom: 2px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}

	.el-half-box {
		height: 122px;
		background-color: rgba($cds-color-border-neutral-decorative,.2);
		display: flex;
		flex: 1;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		border-radius: 6px;

		&.selected {
			box-shadow: 0px 0px 10px 6px var(--theme-primary-700) inset;
		}

		+ .el-half-box {
			margin-left: 2px;
		}
	}

	.el-icon-container {
		width: 50px;
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;

		> i.zmdi, > icon > i {
			color: fade($cds-color-background-neutral-light,90%);
		}

		.el-counter {
			background-color: var(--theme-primary-700);
			border-radius: 26px;
			min-width: 26px;
			height: 26px;
			text-align: center;
			padding: 4px;
			position: absolute;
			margin-right: -30px;
			margin-bottom: -26px;

			&.has-error {
				background-color: $cds-color-text-critical-high-emphasis;
			}

			span {
				line-height: 13px;
				color: $cds-color-text-neutral-high-emphasis;
			}
		}
	}
}