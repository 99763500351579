.structure-export {
	&.definition {
		.definition-detail {
			list-style: none;
			padding-top: 10px;
			padding-left: 10px;
			padding-bottom: 10px;
			padding-right: 10px;

			.header {
				padding-right: 10px;
				height: 30px;

				h4 {
					float: left;
				}
			}

			.content {
				.definition-list {
					list-style: none;
					padding-left: 0;
					margin-left: 20px;

					> li {
						position: relative;
						line-height: 2em;
						margin-top: 10px;
						margin-bottom: 10px;

						&:before, &:after {
							content: '';
							position: absolute;
							left: -10px;
							width: 10px;
							height: calc(50% + 10px);
							border-color: #CCCCCC;
							border-style: solid;
						}

						&:before {
							top: -10px;
							border-width: 0 0 2px 2px;
						}

						&:after {
							top: 50%;
							border-width: 0 0 0 2px;
						}

						&:last-child:after {
							display: none;
						}
					}

					structure-export-definition-line {
						display: flex;
						width: 100%;
					}

					.definition-item {
						display: flex;
						justify-content: space-between;
						align-items: center;
						flex: 1;
						background-color: #EEEEEE;
						text-align: center;
						padding: 5px;
						width: 100%;
					}
				}
			}
		}

		> .definition-detail {
			padding-right: 10px;
		}
	}
}

.rubrique-detail {
	.detail-item {
		padding: 10px;
		background-color: $cds-color-background-neutral-light;
		align-items: center;

		> div {
			display: flex;
			align-items: center;
			gap: 4px;
		}

		span.form-group {
			margin-bottom: 0px;
		}
	}

	.fonction {
		.fonction-detail {
			background-color: $cds-color-action-white-active;
			list-style: none;
			padding-top: 10px;
			padding-left: 10px;
			padding-bottom: 10px;
			padding-right: 10px;

			.header {
				padding-right: 10px;
				height: 24px;
			}

			.content {
				> ul {
					list-style: none;
					padding-left: 0;
					margin-left: 20px;

					> li {
						position: relative;
						line-height: 2em;
						margin-top: 10px;
						margin-bottom: 10px;

						&:before, &:after {
							content: '';
							position: absolute;
							left: -10px;
							width: 10px;
							height: calc(50% + 10px);
							border-color: #CCCCCC;
							border-style: solid;
						}

						&:before {
							top: -10px;
							border-width: 0 0 2px 2px;
						}

						&:after {
							top: 50%;
							border-width: 0 0 0 2px;
						}

						&:last-child:after {
							display: none;
						}
					}

					.fonction-item {
						background-color: $cds-color-background-neutral-light;
						text-align: left;
						padding: 10px;
					}

					.detail-item {
						background-color: $cds-color-background-neutral-light;
						padding-left: 10px;
					}
				}
			}
		}

		&.si {
			.condition {
				.condition-detail {
					list-style: none;
					padding-top: 10px;
					padding-left: 10px;
					padding-bottom: 10px;
					padding-right: 10px;
					border: 1px solid $cds-color-border-neutral-decorative;

					.header {
						padding-right: 10px;
					}

					.content {
						.condition-list {
							list-style: none;
							padding-left: 0;
							margin-left: 20px;

							> li {
								position: relative;
								line-height: 2em;
								margin-top: 10px;
								margin-bottom: 10px;

								&:before, &:after {
									content: '';
									position: absolute;
									left: -10px;
									width: 10px;
									height: calc(50% + 10px);
									border-color: #CCC;
									border-style: solid;
								}

								&:before {
									top: -10px;
									border-width: 0 0 2px 2px;
								}

								&:after {
									top: 50%;
									border-width: 0 0 0 2px;
								}

								&:last-child:after {
									display: none;
								}

								&:first-child:before {
									border-left: none;
								}

								&:first-child:last-child:before {
									display: none;
								}
							}

							.condition-item {
								background-color: $cds-color-background-neutral-light;
								text-align: center;
								padding: 5px;

								.detail-item {
									padding-left: 0px;
									padding-right: 0px;

									&.business-data {
										padding-left: 0px;
										padding-right: 0px;
									}
								}
							}
						}
					}
				}

				> .condition-detail {
					padding-right: 10px;
				}
			}
		}

		.fonction-label {
			padding: 6px 12px;
			line-height: 1;
			text-align: center;
			box-shadow: none;
			border: 1px solid $cds-color-border-neutral-decorative;
			border-radius: 16px;
			font-size: 13px;
			color: $cds-color-text-white-high-emphasis;

			&.head {
				background-color: $cds-color-background-primary-main;
			}

			.zmdi {
				vertical-align: middle;
			}
		}
	}
}
