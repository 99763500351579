.account {
	width: 350px;
	margin-left: 0;
	padding-left: 0;

	.header {
		display: flex;
		flex-direction: column;
		align-items: center;

		> div {
			display: flex;
			flex-direction: column;
			align-items: center;

			p.user {
				@include typography(headline-6);
				font-family: $cds-typography-subtitle-1-family;
				letter-spacing: $cds-typography-body-1-letter-spacing;
				color: $cds-color-text-neutral-high-emphasis;
				display: flex;
				max-width: 350px;
				padding: 0 8px;

				& > span {
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
				}
			}

			> span {
				@include typography(body-2);
			}
		}
	}

	.logout {
		display: flex;
		justify-content: center;
	}

	ul {
		margin-top: 10px;
		padding-left: 0px;
		list-style: none;

		li {
			cursor: pointer;
			padding: 14px 16px;
			border-top: 1px solid $cds-color-border-neutral-decorative;
			display: flex;
			align-items: center;

			&:hover {
				background-color: $cds-color-interaction-neutral-hovered;
			}

			span {
				color: $cds-color-action-neutral-active;
				margin-left: 16px;

				@include typography(subtitle-1);
				letter-spacing: 0.5px;
			}

			icon {
				font-size: xx-large;
				color: $cds-color-text-link-neutral-resting;
			}
		}

		li:last-child {
			border-bottom: 1px solid $cds-color-border-neutral-decorative;
		}
	}

	.connect {
		background-color: $cds-color-brand-neutral-99;
		border-bottom: solid 1px $cds-color-border-neutral-decorative;
		border-top: solid 1px $cds-color-border-neutral-decorative;
		margin-top: 16px;
		padding: 8px;
		@include typography(subtitle-1);

		.connect-as {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;

			p {
				margin: 6px 0;

				&.user {
					display: flex;
					max-width: 350px;
					padding: 0 8px;

					& > span {
						white-space: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;
					}
				}
			}
		}
	}
}