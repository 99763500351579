/* roboto-300 - vietnamese_cyrillic-ext_latin_latin-ext_cyrillic_greek-ext_greek */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url("./files/roboto-300.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./files/roboto-300.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-400 - vietnamese_cyrillic-ext_latin_latin-ext_cyrillic_greek-ext_greek */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("./files/roboto-400.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./files/roboto-400.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-700 - vietnamese_cyrillic-ext_latin_latin-ext_cyrillic_greek-ext_greek */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("./files/roboto-700.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./files/roboto-700.woff")
      format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* poppins-200 - latin-ext_latin_devanagari */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 200;
  src: url("./files/poppins-200.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./files/poppins-200.woff") format("woff"); /* Modern Browsers */
}
/* poppins-300 - latin-ext_latin_devanagari */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  src: url("./files/poppins-300.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./files/poppins-300.woff") format("woff"); /* Modern Browsers */
}
/* poppins-500 - latin-ext_latin_devanagari */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: url("./files/poppins-500.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./files/poppins-500.woff") format("woff"); /* Modern Browsers */
}
/* poppins-600 - latin-ext_latin_devanagari */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  src: url("./files/poppins-600.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./files/poppins-600.woff") format("woff"); /* Modern Browsers */
}

/* Material icons */
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("./files/material-icons.woff2") format("woff2"),
    url("./files/material-icons.woff") format("woff");
}
