@keyframes wave {
	100% {
		-webkit-transform: scale(0.75);
		transform: scale(0.75);
	}
}

.mfb-component__wrap {
	padding: 10px;
	margin: -10px;

	a {
		&.global {
			background-color: var(--theme-primary-700);
			color: $cds-color-background-white-main;
			transform: none;

			i {
				font-weight: 500;
			}

			&:hover {
				icon i {
					animation: wave .5s ease-in-out alternate infinite;
				}
			}
		}

		&:not(.global) {
			background-color: $cds-color-background-white-main;
			color: $cds-color-action-neutral-active;
		}
	}

	.material-icons, .material-icons-round {
		font-weight: inherit;
	}

	[data-mfb-label]:after {
		color: $cds-color-action-white-active;
	}
}

$button-space: 70px;
$slide-speed: .5s;
$number-of-buttons: 10;

@for $i from 4 to $number-of-buttons {
	.mfb-component--tl.mfb-zoomin,.mfb-component--tr.mfb-zoomin {
		.mfb-component__list {
			$distance: $button-space * $i;

			li:nth-child(#{$i}) {
				-webkit-transform: translateY($distance) scale(0);
				transform: translateY($distance) scale(0);
				transition: all $slide-speed;
				transition-delay: ($number-of-buttons - $i) * 0.05s;
			}
		}

		&[data-mfb-toggle="hover"]:hover,&[data-mfb-state="open"] {
			.mfb-component__list {
				$distance: $button-space * $i;

				li:nth-child(#{$i}) {
					-webkit-transform: translateY($distance) scale(1);
					transform: translateY($distance) scale(1);
					transition-delay: $i * 0.05s;
				}
			}
		}
	}

	.mfb-component--bl.mfb-zoomin,.mfb-component--br.mfb-zoomin {
		&.global {
			bottom: 0;
			-webkit-transform: none;
			transform: none;
		}

		&:not(.global) {
			bottom: 70px;
		}

		.mfb-component__list {
			$distance: -1 * $button-space * $i;

			li:nth-child(#{$i}) {
				-webkit-transform: translateY($distance) scale(0);
				transform: translateY($distance) scale(0);
				transition: all $slide-speed;
				transition-delay: ($number-of-buttons - $i) * 0.05s;
			}
		}

		&[data-mfb-toggle="hover"]:hover,&[data-mfb-state="open"] {
			.mfb-component__list {
				$distance: -1 * $button-space * $i;

				li:nth-child(#{$i}) {
					-webkit-transform: translateY($distance) scale(1);
					transform: translateY($distance) scale(1);
					transition-delay: $i * 0.05s;
				}
			}
		}

		.mfb-component__main-icon--active,.mfb-component__main-icon--resting {
			&.no-transform {
				-webkit-transform: none;
				transform: none;

				i {
					font-size: 2em;
				}
			}
		}
	}
}