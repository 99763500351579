.mat-mdc-chip {
	&.mat-mdc-standard-chip {
		@include typography(label);
		--mdc-chip-label-text-color: $cds-color-text-neutral-medium-emphasis;
		font-size: 13px;
		border: 1px solid $cds-color-border-neutral-decorative;

		&:not(.mat-mdc-chip-selected) {
			background-color: transparent !important;
			color: $cds-color-text-neutral-medium-emphasis !important;
		}

		.mat-mdc-chip-remove {
			opacity: 1 !important;
			margin-right: 2px;
		}

		button[mat-icon-button] {
			display: flex;
		}

		&.clickable {
			* {
				cursor: pointer;
			}
		}
	}

	.mat-icon {
		--mdc-chip-with-trailing-icon-trailing-icon-color: $cds-color-action-neutral-inactive;
		color: $cds-color-action-neutral-inactive;
		font-size: 20px !important;
		height: 20px !important;
		width: 20px !important;
	}
}