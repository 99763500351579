.toolbox-modal {
	display: none;
	position: fixed;
	width: 400px;
	height: 515px;
	background-color: $cds-color-background-white-main;
	z-index: 40;
	right: 30px;
	bottom: 30px;
	border-radius: 6px;
	box-shadow: 0 2px 5px rgba(0,0,0,0.16),0 2px 10px rgba(0,0,0,0.12);

	&.large {
		width: 800px;
		height: 715px;
		max-height: 95vh;
		max-width: 95vw;
	}

	&.visible {
		display: flex;
		flex-direction: column;
	}

	.header {
		background-color: $cds-color-background-white-main;
		color: $cds-color-border-neutral-high-emphasis;
		border-bottom: solid 1px $cds-color-border-neutral-decorative;
		flex: none;

		.title {
			padding: 16px;
			cursor: move;
			display: flex;
			justify-content: space-between;
			align-items: center;

			& > span {
				flex: 1;
				font-size: 2em;
			}

			.dropdown {
				font-size: 1em;
			}

			& > icon {
				font-size: 2em;
				cursor: pointer;
				color: $cds-color-border-neutral-decorative;
			}
		}

		.magicsearch {
			margin: 0px 10px 10px 10px;
			background-color: transparent;

			form {
				padding-top: 10px;
				padding-bottom: 10px;
			}

			&.local {
				&:before {
					left: 10px;
					top: 17px;
					font-size: 1.2em;
				}

				ul.liste_search {
					margin-top: 0;

					input {
						width: inherit;
					}
				}

				ul.pagination {
					width: auto;
					margin: 0;
					padding-top: 6px;
				}
			}
		}
	}

	.body {
		flex: 1 1 auto;
		overflow-y: hidden;

		& > .page-content-item {
			height: 100%;

			& > div {
				height: 100%;
				display: flex;
				flex-direction: column;
				align-items: flex-start;

				& > div {
					padding: 10px 10px 5px 10px;
					width: 100%;
					flex: none;

					mat-chip-listbox {
						padding: 0px;
					}
				}

				& > list-view {
					padding: 0px;
					width: 100%;
					flex: 1 1 auto;
					overflow-y: hidden;
					display: flex;
					flex-direction: column;

					& > infinite-scroll {
						overflow-y: hidden;
						flex: 1 1 auto;
						display: flex;
						flex-direction: column;

						.listview {
							display: flex;
							flex-direction: column;
							flex: 1 1 auto;
							overflow-y: hidden;

							.lv-header-alt {
								padding: 5px;
								flex: none;

								.magicsearch {
									margin-top: 0px;
								}
							}

							.body {
								overflow-y: scroll;
								overflow-x: hidden;
								flex: 1 1 auto;

								.lv-item {
									padding: 0px;

									.task {
										display: flex;
										padding: 5px 0px 5px 0px;

										.task-check {
											margin-left: 15px;
											margin-right: 15px;
											flex: none;
										}

										.task-content {
											width: 150px;
											flex: 1 2 auto;

											&.done {
												text-decoration: line-through $cds-color-border-primary-disabled;

												a {
													color: $cds-color-text-neutral-disabled;
												}
											}

											.task-title {
												overflow-x: hidden;
												text-overflow: ellipsis;
											}

											.task-attrs {
												list-style: none;

												& > li {
													&.info {
														border: none;
														background-color: rgba(var(--theme-primary-700_rgb),.12);
														line-height: 20px;

														&:hover {
															background-color: rgba(var(--theme-primary-700_rgb),.06);
														}

														&.md {
															max-width: 200px;
															text-overflow: ellipsis;
														}

														&.sm {
															max-width: 160px;
															text-overflow: ellipsis;
														}

														& > span {
															color: var(--theme-primary-700);
														}
													}
												}
											}
										}

										.task-action {
											flex: none;
										}

										& > span {
											display: flex;
											justify-content: center;
											align-items: center;
										}
									}
								}
							}
						}
					}
				}
			}
		}

		todo-list-item-edit {
			height: calc(100% - 85px);

			form {
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				height: 100%;

				& > .form-content {
					height: 100%;
					overflow-y: scroll;
					overflow-x: hidden;
					padding-top: 15px;

					& > .row {
						margin-left: 0px;
						margin-right: 0px;

						& > .form-group {
							padding-left: 20px;
							padding-right: 20px;
						}
					}

					& > basket {
						& > .row {
							margin-left: 0px;
							margin-right: 0px;
							padding-left: 20px;
							padding-right: 20px;

							& > .form-group {
								& > label {
									padding-left: 0px;
									padding-right: 0px;
								}

								& > div {
									padding-left: 0px;
									padding-right: 0px;
								}
							}
						}

						& > div:last-child {
							padding-left: 20px;
						}
					}

				}

				.footer {
					border-top: solid 1px $cds-color-specific-divider-neutral;
					display: flex;
					justify-content: center;
					gap: 10px;
					padding: 15px;
					text-align: right;
				}
			}
		}

	}

	.footer {
		border-top: solid 1px $cds-color-specific-divider-neutral;
		display: flex;
		justify-content: center;
		gap: 10px;
		padding: 15px;
		text-align: right;
		flex: none;
	}
}
