.rule {
	.rule-detail {
		list-style: none;
		padding: 10px;
		border-radius: 6px;

		.header {
			padding-right: 10px;
		}

		.content {
			.rule-list {
				list-style: none;
				padding-left: 0;
				margin-left: 20px;

				> li {
					position: relative;
					line-height: 2em;
					margin-top: 10px;
					margin-bottom: 10px;

					&:before,&:after {
						content: '';
						position: absolute;
						left: -10px;
						width: 10px;
						height: calc(50% + 10px);
						border-color: $cds-color-border-neutral-decorative;
						border-style: solid;
					}

					&:before {
						top: -10px;
						border-width: 0 0 2px 2px;
					}

					&:after {
						top: 50%;
						border-width: 0 0 0 2px;
					}

					&:last-child:after {
						display: none;
					}
				}

				.rule-item {
					padding: 5px;
					border-radius: 6px;
					background-color: $cds-color-background-neutral-light;
					text-align: center;

					> a {
						line-height: 40px;
					}

					&.light {
						border: 1px solid $cds-color-border-neutral-decorative;
					}

					> fieldset {
						display: flex;
						align-items: center;
						justify-content: space-between;
						gap: 5px;

						.form-group {
							margin-bottom: 0;
						}

						> .field {
							display: flex;
							flex-basis: 30%;
							align-items: center;
							padding-left: 5px;
							gap: 5px;
							text-align: left;
						}

						> .agregation,> .operateur,> .date-day {
							flex-basis: 20%;
						}

						> .value {
							flex: 1;
							gap: 5px;
							text-align: left;

							.between {
								display: flex;
								flex-direction: row;
								align-items: center;
								gap: 5px;
							}
						}
					}
				}
			}
		}
	}

	> .rule-detail {
		padding-right: 10px;
	}
}