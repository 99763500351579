.access-forbidden {
	h1 {
		font-size: 8em;
		color: $cds-color-text-neutral-high-emphasis;
	}

	h2 {
		color: $cds-color-text-neutral-medium-emphasis;
	}

	icon[class="icon-hc-16x"] {
		color: $cds-color-text-neutral-high-emphasis;
	}
}