.modal {
	.modal-header {
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;
		background-color: $cds-color-background-white-main;
		border-bottom: solid 1px $cds-color-border-neutral-decorative;

		.modal-title {
			color: $cds-color-text-neutral-high-emphasis;
			@include typography(headline-6);
		}
	}

	.modal-content {
		border-radius: 6px;
		margin-top: 50px;
		backdrop-filter: blur(10px);

		.modal-body {
			.label {
				margin-top: 0px;
			}

			.card {
				&.focus {
					background-color: $cds-color-background-white-main;
				}
			}
		}
	}

	.modal-body {
		margin-top: 16px;
	}

	&.bootbox + .modal-backdrop {
		z-index: 1085;
	}

	.modal-footer {
		border-top: solid 1px $cds-color-border-neutral-decorative;
		display: flex;
		justify-content: center;
		gap: 10px;
	}
}

modal-container {
	.modal-content {
		&:after {
			position: absolute;
			content: '';
			height: 100%;
			width: 100%;
			transition: background .5s ease-out;
			background: rgba(0,0,0,.5);
			top: 0;
			z-index: 1;
		}
	}

	&:last-of-type {
		.modal-content {
			&:after {
				background: transparent;
				height: 0;
			}
		}
	}
}