.profil {
	.liste_menu {
		padding-left: 0;
		list-style: none;

		div.menu {
			display: inline-block;
			width: 100%;
			padding: 5px;
			border-bottom: solid 1px rgba($cds-color-border-neutral-decorative,0.05);

			.checkbox {
				margin-top: 5px;
				margin-bottom: 5px;
				margin-left: 10px;

				@media (min-width: $cds-size-breakpoint-sm) {
					display: inline-block;
					margin-top: 0;
					margin-bottom: 0;
				}
			}
		}

		ul {
			list-style: none;
		}
	}

	.checkbox {
		label {
			position: relative;
			padding-left: 30px;
		}

		input {
			z-index: 1;
			top: 0;
			left: 0;
			width: 30px;
			height: 30px;
			margin-top: 0;
			margin-left: 0 !important;
			cursor: pointer;
			opacity: 0;

			&:checked + .input-circle-helper {
				&:before {
					transform: scale(0);
				}

				&:after {
					transform: scale(1);
					opacity: 1;
				}
			}
		}

		&.checkbox-all {
			.input-circle-helper {
				&:before, &:after {
					content: '\f267';
					font-family: 'Material-Design-Iconic-Font';
				}
			}
		}

		.input-circle-helper {
			font-style: normal;
			line-height: 30px;
			font-family: 'Material-Design-Iconic-Font';
			text-align: center;

			&.edit {
				&:before, &:after {
					content: '\f158';
				}
			}

			&.delete {
				&:before, &:after {
					content: '\f154';
				}
			}

			&.consult {
				&:before, &:after {
					content: '\f15c';
				}
			}

			&:before, &:after {
				position: absolute;
				top: -6px;
				width: 30px;
				height: 30px;
				border: none;
				border-radius: 50%;
				backface-visibility: hidden;
				@include transition(all);
				@include transition-duration(250);
			}

			&:before {
				left: 0;
				background-color: rgba($cds-color-border-neutral-decorative,0.05);
				color: $cds-color-text-neutral-high-emphasis;
			}

			&:after {
				left: -1px;
				transform: scale(0) rotate(80deg);
				background-color: $cds-color-action-primary-active;
				color: $cds-color-action-white-active;
				opacity: 0;
			}
		}

		&.disabled {
			opacity: 0.4;
		}
	}

	&.profil-edit {
		.checkbox {
			width: 90px;
			text-align: center;
		}
	}
}