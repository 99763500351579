.form-group {
	display: flex;
	flex-direction: column;
	margin-bottom: 8px;

	label {
		color: $cds-color-text-neutral-medium-emphasis;
		@include typography(label);
		font-size: $n-label-input-font-size;
		margin-bottom: 2px;
	}

	& > label {
		position: relative;
		align-self: flex-start;
		max-width: 100%;
	}

	&:has(:required) {
		& > label {
			padding-right: 1em;

			&:after {
				content: '*';
				color: $cds-color-text-critical-high-emphasis;
				position: absolute;
				right: 0;
				top: 0;
			}
		}
	}

	.form-control {
		color: $cds-color-text-neutral-medium-emphasis !important;
		@include typography(body-1);
		font-size: $n-label-input-font-size;
		height: 34px;

		border: 1px solid $cds-color-border-neutral-decorative;
		border-radius: 6px !important;
		padding-left: 8px;
		padding-right: 8px;
		padding-top: 1px;
		padding-bottom: 1px;

		&:hover {
			border: 1px solid $cds-color-border-neutral-high-emphasis;
			background-color: rgba($cds-color-border-neutral-high-emphasis,.06);
		}

		&:focus,&:has(mat-select:focus) {
			border: 2px solid var(--theme-primary-700);
			padding-left: 7px;
			padding-right: 7px;
			padding-top: 0;
			padding-bottom: 0;
			background-color: transparent;
		}

		& > mat-select {
			color: $cds-color-text-neutral-medium-emphasis !important;
			@include typography(body-1,!important);
			font-size: $n-label-input-font-size !important;
		}
	}

	textarea.form-control {
		height: auto;
	}

	&.has-error {
		.form-control {
			border-color: $cds-color-text-critical-high-emphasis;
		}

		.input-group-addon {
			color: $cds-color-text-critical-high-emphasis;
			border-color: $cds-color-text-critical-high-emphasis !important;
		}

		autocomplete {
			.input-group {
				border-color: $cds-color-text-critical-high-emphasis;
			}
		}

		label {
			color: $cds-color-text-critical-high-emphasis;
			padding-right: 1em;

			&:after {
				content: '*';
				position: absolute;
				right: 0;
				top: 0;
			}

			&[for] {
				&:after {
					right: auto;
					top: auto;
					margin-left: 5px;
				}
			}
		}
	}

	mat-checkbox {
		height: 34px;
		display: flex;
		align-items: center;
		left: -11px;

		label {
			padding-left: 0;
			margin: 0;
		}
	}

	.mat-checkbox-label {
		white-space: pre-wrap;
	}

	.adresse-content {
		display: flex;
		flex-direction: row;
		line-height: 34px;
	}

	.input-group {
		display: flex;
		flex-direction: row;

		> autocomplete {
			flex: 1;
		}

		> a {
			margin-top: 0px;
			line-height: 34px;
		}

		.input-group-addon:not(:empty) {
			display: flex;
			margin-left: 8px;
			justify-content: center;
			align-items: center;
			border: 1px solid $cds-color-border-neutral-decorative;
			border-radius: 6px;

			&.no-border {
				border: none;
			}

			&.extend-1 {
				min-width: 80px;
			}

			&.extend-2 {
				min-width: 120px;
			}

			> button.mat-mdc-icon-button {
				height: 34px;
				width: 34px;
				padding: 0;
			}
		}
	}

	> div {
		&.half {
			width: 50%;
		}

		> div[ng-reflect-type-attachment], > span, > icon {
			line-height: 34px;
		}

		> div > a, > a {
			line-height: 27px;
		}

		> color-picker {
			line-height: 34px;

			input {
				border: 1px solid $cds-color-border-neutral-decorative;
				height: 34px;
			}

			button:hover {
				background-color: rgba(var(--theme-primary-700_rgb),0.05);
			}
		}

		.mat-chip-listbox-wrapper {
			margin: 2px 0 0 0;

			.mat-standard-chip {
				margin: 0;
			}
		}

		.mdc-evolution-chip-set {
			.mdc-evolution-chip {
				margin-top: 1px;
				margin-bottom: 1px;
			}
		}
	}
}

form {
	.pull-right {
		& > button:not(:first-child) {
			margin-left: 10px;
		}
	}
}

basket {
	.form-group {
		&.has-error {
			label {
				&:after {
					right: auto;
					top: auto;
					margin-left: 5px;
				}
			}
		}
	}
}