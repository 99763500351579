global-search {
	width: 100%;

	.global-search {
		position: relative;

		.suggestion-wrapper {
			display: none;
			position: absolute;
			float: left;
			z-index: 1;
			margin-top: 3px;
			box-shadow: 0px 5px 5px -3px rgba($cds-color-brand-neutral-20,.12),0px 3px 14px 2px rgba($cds-color-brand-neutral-20,.12),0px 8px 10px 1px rgba($cds-color-brand-neutral-20,.08);
			border-radius: 6px;
			background-color: $cds-color-background-white-main;
			width: 100%;
			max-height: 312px;
			overflow-y: auto;

			ul {
				margin-top: 0;
				margin-bottom: 0;
				padding-left: 0;
				list-style: none;
			}

			.empty {
				padding: 20px 16px;
				color: rgba($cds-color-text-neutral-high-emphasis,.32);

				@include typography(body-1);
			}

			.liste-agregats {
				display: flex;
				flex-direction: column;

				.agregat {
					padding: 12px 16px;
					cursor: pointer;

					&.current, &:hover:not(&.empty) {
						background-color: rgba($cds-color-interaction-neutral-hovered,.06);
					}

					a {
						display: flex;
						justify-content: space-between;
						align-items: center;

						color: $cds-color-text-neutral-high-emphasis;
						@include typography(body-1);

						.counter {
							font-weight: 700;
							font-size: 12px;
							line-height: 16px;
						}
					}
				}
			}
		}

		.input-search-wrapper {
			display: flex;
			align-items: center;
			border: 1px solid $cds-color-border-neutral-decorative;
			border-radius: 100px;
			background-color: $cds-color-background-primary-light;
			padding-right: 16px;
			padding-left: 16px;
			height: 42px;

			&:hover {
				border-color: $cds-color-border-neutral-high-emphasis;
			}

			.icon {
				margin-right: 8px;
				margin-left: 8px;
			}

			.wait {
				margin-left: -4px;
				width: 40px;
			}

			> input {
				border: none;
				background-color: inherit;
				padding-right: 8px;
				padding-left: 8px;
				width: 100%;
				text-overflow: ellipsis;
				@include typography(subtitle-1);
			}
		}

		&.focus {
			.input-search-wrapper {
				box-shadow: rgba($cds-color-brand-neutral-10,.08) 0px 1px 1px 0px,rgba($cds-color-brand-neutral-10,.12) 0px 2px 1px -1px,rgba($cds-color-brand-neutral-10,.12) 0px 1px 3px 0px;
				border-color: transparent !important;
				background-color: $cds-color-background-white-main;
			}

			.suggestion-wrapper {
				display: block;
			}
		}
	}
}