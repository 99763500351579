loader {
	position: absolute;
	left: 50%;
	top: 100px;
	margin-top: -26px;
	margin-left: -26px;
	padding: 8px;
	border-radius: 50%;
	z-index: 9999;
	background-color: $cds-color-action-white-active;
}