.comparator {
	position: relative;
	padding-left: 0px !important;
	padding-right: 0px !important;
	margin-bottom: 10px;
	margin-top: 10px;

	.head-info {
		z-index: 1;
		padding: 10px;
		position: relative;
		text-align: center;

		> span {
			color: var(--theme-primary-700);
		}
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;

		li {
			border: 1px solid $cds-color-extended-grey-40;
			height: 60px;
			border-bottom: 0px;
			text-align: center;
			line-height: 60px;

			.counter {
				background-color: $cds-color-extended-grey-95;
				height: 25px;
				min-width: 40px;
				border-radius: 15px 0px 0px 15px;
				margin-top: 5px;
				position: absolute;
				right: 0px;
				top: 0px;

				> span {
					font-size: 12px;
					white-space: normal;
					z-index: 1;
					position: relative;
					line-height: 25px;
					vertical-align: top;
				}
			}

			.attachment {
				min-width: 30px;
				height: 30px;
				border-radius: 35px;
				text-align: center;
				padding-left: 5px;
				padding-right: 5px;
				cursor: pointer;
				line-height: 30px;
				display: inline-block;

				span {
					line-height: 30px;
					display: inline-block;
				}
			}

			&.title {
				text-align: left;
				padding-left: 10px;

				span {
					display: block;
				}

				.sub-section {
					position: relative;

					.sub-text {
						font-size: 11px;
						font-style: italic;
					}

					.attachment {
						position: absolute;
						top: -5px;
						right: 5px;
						min-width: 30px;
						height: 30px;
						border-radius: 35px;
						text-align: center;
						padding-left: 5px;
						padding-right: 5px;
						cursor: pointer;

						span {
							line-height: 30px;
						}
					}
				}
			}
		}

		&.caption {
			float: left;
			background: $cds-color-background-primary-light;

			li {
				width: 200px;
				text-align: left;
				padding-left: 10px;
			}
		}

		&.sections {
			float: left;
			background: $cds-color-background-primary-light;
			width: 200px;

			&:after {
				position: absolute;
				content: ' ';
			}

			> li {
				width: 200px;
				overflow: hidden;
				text-overflow: ellipsis;

				span {
					line-height: 30px;
				}
			}

			ul li {
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;

				> span {
					text-align: left;
					display: block;
					padding-left: 10px;
					line-height: 30px;
				}

				.sub-title {
					font-size: 11px;
				}

				.only-one-title {
					position: relative;
					top: 25%;
				}
			}
		}

		&.items {
			width: 100%;
			white-space: nowrap;
			transition: all 300ms ease;

			> li {
				border: 0px;
				position: relative;
				display: inline-block;
				width: 400px;

				.item-content {
					position: relative;
					overflow: hidden;
					text-overflow: ellipsis;
					padding-right: 10px;
					padding-left: 10px;
				}

				@media(max-width: 1500px) {
					width: 280px;
				}

				ul.datas {
					> li, > ul > li {
						width: 100%;
						border-left: 0px;
					}
				}
			}
		}
	}

	.header {
		min-height: 80px;
		height: 80px;

		li {
			height: 80px;
			min-height: 80px;

			> span {
				line-height: 25px;
			}
		}

		& > .content {
			position: absolute;
			width: calc(100% - 200px);
			left: 200px;
			overflow-x: hidden;
			min-height: 80px;
			height: 80px;

			ul.items {
				li {
					border: 1px solid $cds-color-extended-grey-40;
					border-bottom: 0px;
					border-left: 0px;
					background: $cds-color-background-primary-light;
				}
			}
		}
	}

	.body {
		overflow-y: scroll;

		& > .content {
			width: calc(100% - 200px);
			left: 225px;
			overflow: hidden;
			padding-bottom: 0px;

			.item-selected {
				background-color: $cds-color-interaction-success-selected;
			}

			.navigation {
				position: absolute;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 40px;
				text-align: center;
				height: 100%;
				top: 0;
				opacity: 0.3;
				cursor: pointer;
				transition: opacity 500ms;

				&.hidden {
					display: none;
				}

				&.left {
					left: 225px;
				}

				&.right {
					right: 50px;
				}

				&:after {
					content: ' ';
					display: table;
				}

				&:hover {
					opacity: 1.0;
				}

				i {
					display: table-cell;
					vertical-align: middle;
				}
			}
		}

		@for $nbCriteres from 0 to 11 {
			$size: local value;

			@if $nbCriteres < 10 {
				$size: $nbCriteres;
			} @else {
				$size: 'max';
			}

			&.body-length-#{$size} {
				@if $nbCriteres < 10 {
					height: calc(60px * $nbCriteres + 10px);
				} @else {
					height: calc(100vh - 300px);
				}
			}
		}
	}

	@for $position from 0 to 50 {
		.position-#{$position} {
			margin-left: -400px * $position;

			@media(max-width: $cds-size-breakpoint-lg) {
				margin-left: -280px * $position;
			}
		}
	}

	.list-actions {
		border: none;
		background-color: $cds-color-background-white-main;

		button {
			margin-top: 20px;
		}
	}

	.more-info {
		.title {
			padding: 30px;
		}

		.sub-title {
			padding-left: 60px;
		}

		.content {
			padding-left: 60px;
			padding-right: 30px;
			overflow-wrap: break-word;
		}
	}

	li {
		&.last {
			border-bottom: 1px solid $cds-color-extended-grey-40;
		}
	}
}

.modal-dialog {
	@for $position from 0 to 4 {
		&.modal-comparator-#{$position} {
			width: 200px + (400px * $position);
			max-width: 99%;
		}
	}

	&.modal-comparator-max {
		width: 99%;
		max-width: 99%;
	}
}