button {
	&[mat-icon-button].mat-mdc-icon-button {
		display: flex !important;
		align-items: center !important;
		justify-content: center !important;

		.mat-icon {
			color: $cds-color-text-neutral-medium-emphasis;
		}
	}

	&[mat-button], .mat-mdc-button.mat-unthemed {
		--mdc-text-button-label-text-color: $cds-color-extended-grey-40 !important;
	}
}

.mat-button {
	.mat-button-wrapper {
		display: flex;
	}
}