summernote {
	.note-editor {
		border: 1px solid $cds-color-border-neutral-decorative !important;
		border-radius: 6px !important;

		.note-toolbar {
			background-color: $cds-color-background-neutral-light !important;
			border-bottom: 1px solid $cds-color-border-neutral-decorative !important;
			border-top-left-radius: 6px !important;
			border-top-right-radius: 6px !important;
		}

		.note-statusbar {
			background-color: $cds-color-background-neutral-light !important;
			border-top: 1px solid $cds-color-border-neutral-decorative !important;
			border-bottom-left-radius: 6px !important;
			border-bottom-right-radius: 6px !important;
		}
	}

	.note-modal {
		.checkbox {
			accent-color: var(--theme-primary-500) !important;
			height: 34px;
			display: flex;
			align-items: center;

			label {
				color: $cds-color-text-neutral-medium-emphasis;
				@include typography(label);
				font-size: $n-label-input-font-size;
				margin-bottom: 2px;
				margin: 0;

				input {
					display: inline-block;
					flex: 0 0 18px;
					box-sizing: content-box;
					width: 18px;
					height: 18px;
					line-height: 0;
					white-space: nowrap;
					cursor: pointer;
					vertical-align: bottom;
					padding: calc((var(--mdc-checkbox-state-layer-size,40px) - 18px) / 2);
					margin: calc((var(--mdc-checkbox-state-layer-size,40px) - var(--mdc-checkbox-state-layer-size,40px)) / 2);
				}
			}
		}

		.note-btn {
			border-radius: 4px !important;
			border: 0px !important;
			padding: 0px 16px !important;
			line-height: 20px !important;
			height: 32px !important;
			font-weight: 600 !important;
		}

		.note-btn-primary {
			background-color: var(--theme-primary-500) !important;

			&:hover {
				box-shadow: var(--mdc-protected-button-hover-container-elevation-shadow) !important;
			}

			&:active {
				box-shadow: var(--mdc-protected-button-hover-container-elevation-shadow) !important;
			}

			&:disabled {
				color: var(--mdc-protected-button-disabled-label-text-color) !important;
				background-color: var(--mdc-protected-button-disabled-container-color) !important;
			}
		}
	}
}