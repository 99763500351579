.chatbot {
	&.minimized {
		height: 60px;
		bottom: 0px;
		transform: translate3d(0px,0px,0px) !important;

		.hidden {
			display: none;
		}
	}

	.header {
		icon {
			&.option-window {
				margin-right: 5px;

				&.minimize {
					font-size: 31px;
					margin-top: -8px;
				}
			}
		}

		button {
			margin-right: 14px;
		}
	}

	.body {
		overflow-y: scroll;
		position: relative;
		height: 400px;
		padding-top: 20px;
		padding-left: 5px;

		&.has-content {
			padding-bottom: 60px;
		}

		.chip {
			margin: 4px;
			font-size: $cds-typography-caption-font-size;
			border: 1px solid $cds-color-border-neutral-decorative;
			border-radius: 6px;
			padding: 6px;
			cursor: pointer;
			margin-inline: 20px;
			padding-left: 10px;
			width: fit-content;

			a {
				display: flex;
				align-items: center;

				span {
					padding-left: 4px;
					padding-right: 8px;
				}

				icon {
					font-size: 1.4em;
				}
			}
		}
	}

	.footer {
		border-top: solid 1px $cds-color-border-neutral-decorative;
		background-color: $cds-color-background-white-main;
		bottom: 0px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.new-text {
			padding: 8px;
			width: 100%;

			& > .form-group {
				margin-bottom: 0px;

				a {
					margin-top: 0px;
					margin-right: 0px;

					i {
						margin-right: 0px;
					}
				}
			}

			icon {
				margin-top: 0px;
				font-size: 22px;
			}

			textarea::placeholder {
				line-height: 24px;
			}
		}
	}
}

.feedback {
	max-width: 430px;
	padding: 30px 20px;
}

.feedback, .chatbot {
	.bubble {
		position: relative;
		border-radius: .4em;
		padding: 10px;
		margin-left: 10px;

		&.user {
			background: $cds-color-border-primary-high-emphasis;
			color: $cds-color-background-white-main;
			float: right;
			margin-right: 15px;
		}

		&.bot {
			background: $cds-color-background-white-main;
			border: 2px solid $cds-color-border-neutral-decorative;
			float: left;
			margin-left: 20px;
			margin-right: 20px;

			.actions {
				margin-top: 12px;
				text-align: center;
			}

			ul {
				margin-left: -22px;
			}
		}
	}

	.rating {
		line-height: 22px;

		icon > i,i {
			font-size: 25px;
		}
	}

	.comment-container {
		margin-top: 15px;

		.tips {
			font-size: 11px;
		}

		textarea {
			margin-top: 5px;
		}
	}

	.selection-scenario {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
		backdrop-filter: blur(4px);
		display: flex;
		flex-direction: column;

		.selection-scenario-content {
			flex: 1;
			overflow-y: scroll;
			padding-left: 40px;
			padding-top: 40px;
			font-size: $cds-typography-subtitle-1-font-size;

			.lv-body {
				backdrop-filter: blur(4px);
				background-color: transparent !important;
			}
		}
	}
}