.popover {
	max-width: 100%;

	.popover-title {
		word-wrap: break-word;
		color: var(--theme-primary-700);
	}

	.popover-content {
		padding-left: 0px;
		padding-right: 0px;

		.selected {
			background-color: rgba(var(--theme-primary-700_rgb),.2);
		}
	}

	&.fit-content {
		max-width: fit-content;
	}
}