.grille-fluidite {
	.modal-header {
		> div {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}

	.modal-body {
		position: relative;
		padding-left: 0px !important;
		padding-right: 0px !important;

		ul {
			list-style: none;
			padding: 0;
			margin: 0;

			li {
				border: 1px solid $cds-color-extended-grey-40;
				height: 30px;
				border-bottom: 0px;
				text-align: center;
				line-height: 30px;

				&.title {
					text-align: center;

					span {
						display: block;
					}
				}
			}

			&.caption {
				float: left;
				background: $cds-color-background-primary-light;

				li {
					width: 100px;
					text-align: left;
					padding-left: 10px;
				}
			}

			&.sections {
				float: left;
				background: $cds-color-background-primary-light;
				width: 100px;

				&:after {
					position: absolute;
					content: ' ';
				}

				> li {
					width: 100px;
					overflow: hidden;
					text-overflow: ellipsis;

					span {
						line-height: 30px;
					}

					&:last-child {
						border-bottom: 1px solid $cds-color-extended-grey-40;
					}
				}

				ul li {
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;

					span {
						text-align: left;
						display: block;
						padding-left: 10px;
						line-height: 30px;
					}
				}
			}

			&.items {
				width: 100%;
				white-space: nowrap;
				transition: all 300ms ease;

				> li {
					border: 0px;
					position: relative;
					display: inline-block;
					width: 70px;

					.item-content {
						position: relative;
						overflow: hidden;
						text-overflow: ellipsis;
						padding-right: 0;
						padding-left: 0;

						&.high {
							background-color: rgba($cds-color-background-success-main,.5) !important;
						}

						&.medium {
							background-color: rgba($cds-color-background-warn-main,.5) !important;
						}

						&.low {
							background-color: rgba($cds-color-background-critical-main,.5) !important;
						}

						&.outdated {
							background-color: rgba(0,0,0,0.05);
						}

						&.current-loi-roulage {
							background-color: rgba(0,0,0,0.1);
						}

						&.label-good {
							font-size: 1.1em;
						}

						&.label-bad {
							font-style: italic;
						}
					}

					ul.datas {
						> li, > ul > li {
							width: 100%;
							border-left: 0px;
						}

						> li {
							&:last-child {
								border-bottom: 1px solid $cds-color-extended-grey-40;
							}
						}
					}
				}
			}
		}

		.header {
			min-height: 30px;
			height: 30px;

			li {
				height: 30px;
				min-height: 30px;

				> span {
					line-height: 30px;
				}
			}

			& > .content {
				position: absolute;
				width: calc(100% - 100px);
				left: 100px;
				overflow-x: hidden;
				min-height: 80px;
				height: 80px;

				ul.items {
					li {
						border: 1px solid $cds-color-extended-grey-40;
						border-bottom: 0px;
						border-left: 0px;
						background: $cds-color-background-primary-light;
					}
				}
			}
		}

		.body {
			overflow-y: scroll;
			overflow-x: scroll;

			& > .content {
				width: calc(100% - 100px);
				left: 125px;
				overflow: hidden;
				padding-bottom: 20px;

				.item-selected {
					background-color: $cds-color-interaction-success-selected;
				}

				.navigation {
					position: absolute;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 40px;
					text-align: center;
					height: 100%;
					top: 0;
					opacity: 0.3;
					cursor: pointer;
					transition: opacity 500ms;

					&.hidden {
						display: none;
					}

					&.left {
						left: 125px;
					}

					&.right {
						right: 50px;
					}

					&:after {
						content: ' ';
						display: table;
					}

					&:hover {
						opacity: 1.0;
					}

					i {
						display: table-cell;
						vertical-align: middle;
					}
				}
			}

			@for $nbCriteres from 0 to 51 {
				$size: local value;

				@if $nbCriteres < 50 {
					$size: $nbCriteres;
				} @else {
					$size: 'max';
				}

				&.body-length-#{$size} {
					@if $nbCriteres < 50 {
						height: 31px * $nbCriteres;
					} @else {
						height: calc(100vh - 150px);
					}
				}
			}
		}

		@for $position from 0 to 50 {
			.position-#{$position} {
				margin-left: -70px * $position;
			}
		}
	}
}

.grille-fluidite-detail {
	.alert {
		color: $cds-color-extended-grey-40;

		&.high {
			background-color: rgba($cds-color-background-success-main,.5) !important;
		}

		&.medium {
			background-color: rgba($cds-color-background-warn-main,.5) !important;
		}

		&.low {
			background-color: rgba($cds-color-background-critical-main,.5) !important;
		}

		&.none {
			background-color: $cds-color-extended-grey-70;
		}
	}

	table {
		td {
			&.current-loi-roulage {
				background-color: $cds-color-extended-grey-70;
			}
		}
	}
}