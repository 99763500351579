.nav {
	&.nav-pills {
		> li {
			> a {
				color: $cds-color-text-neutral-medium-emphasis;
				border-radius: 0px;
			}

			&.active > a {
				&, &:hover, &:focus {
					color: var(--theme-primary-700);
					background-color: rgba(var(--theme-primary-700_rgb),0.16);
				}
			}
		}

		&.nav-pills-stepper {
			> li {
				margin: 0;

				> a[data-step] {
					padding-left: 50px;
					border-bottom: 1px solid $cds-color-border-neutral-decorative;

					&:before {
						content: attr(data-step);
						position: absolute;
						left: 20px;
						width: 20px;
						height: 20px;
						border-radius: 50%;
						text-align: center;
					}
				}

				.subtitle {
					font-size: 11px;
				}


				&.done:not(.active) {
					> a[data-step] {
						&:before {
							content: '\f26b';
							font-family: 'Material-Design-Iconic-Font';
							line-height: 20px;
						}
					}
				}

				&.has-error {
					> a[data-step] {
						&:before {
							content: '\f1f4';
							font-family: 'Material-Design-Iconic-Font';
							line-height: 20px;
						}
					}
				}

				&.disabled {
					cursor: not-allowed;
				}
			}
		}
	}
}
