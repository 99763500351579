/**
 * Utilisation du thème Angular Material
 */
@use '@angular/material' as mat;
@use 'sass:map';

@include mat.core();

@import '@cegid/design-tokens/build/_tokens.scss';

/**
 * Palette pour la couleur primaire
 */
$mat-notilus-primary: (
	50: var(--theme-primary-50),
	100: var(--theme-primary-100),
	200: var(--theme-primary-200),
	300: var(--theme-primary-300),
	400: var(--theme-primary-400),
	500: var(--theme-primary-500),
	600: var(--theme-primary-600),
	700: var(--theme-primary-700),
	800: var(--theme-primary-800),
	900: var(--theme-primary-900),
	A100: var(--theme-primary-A100),
	A200: var(--theme-primary-A200),
	A400: var(--theme-primary-A400),
	A700: var(--theme-primary-A700),
	contrast: (
		50: rgba(black,1),
		100: rgba(black,1),
		200: rgba(black,1),
		300: white,
		400: white,
		500: rgba(white,1),
		600: rgba(white,1),
		700: rgba(white,1),
		800: rgba(white,1),
		900: rgba(white,1),
		A100: rgba(black,1),
		A200: white,
		A400: white,
		A700: rgba(white,1)
	)
);

/**
 * Palette pour la couleur accentuée
 */
$mat-notilus-accent: (
	50: var(--theme-accent-50),
	100: var(--theme-accent-100),
	200: var(--theme-accent-200),
	300: var(--theme-accent-300),
	400: var(--theme-accent-400),
	500: var(--theme-accent-500),
	600: var(--theme-accent-600),
	700: var(--theme-accent-700),
	800: var(--theme-accent-800),
	900: var(--theme-accent-900),
	A100: var(--theme-accent-A100),
	A200: var(--theme-accent-A200),
	A400: var(--theme-accent-A400),
	A700: var(--theme-accent-A700),
	contrast: (
		50: rgba(black,1),
		100: rgba(black,1),
		200: rgba(black,1),
		300: white,
		400: white,
		500: rgba(white,1),
		600: rgba(white,1),
		700: rgba(white,1),
		800: rgba(white,1),
		900: rgba(white,1),
		A100: rgba(black,1),
		A200: white,
		A400: white,
		A700: rgba(white,1)
	)
);

/**
 * Définition des variables du thème
 */
$notilus-app-primary: mat.m2-define-palette($mat-notilus-primary);
$notilus-app-accent: mat.m2-define-palette($mat-notilus-accent);
$notilus-app-warn: mat.m2-define-palette(mat.$m2-red-palette);

/**
 * Définition du thème personnalisé
 */
$notilus-app-theme: mat.m2-define-light-theme((
	color: (
		primary: $notilus-app-primary,
		accent: $notilus-app-accent,
		warn: $notilus-app-warn
	)
));

/**
 * Personnalisation du texte
 */
$notilus-app-theme: map.set($notilus-app-theme,color,foreground,text,$cds-color-action-neutral-inactive);

/**
 * Utilisation du thème personnalisé
 */
@include mat.all-component-themes($notilus-app-theme);

/**
 * Définition de la font personnalisée
 */
$notilus-app-typography: mat.m2-define-typography-config(
	$font-family: 'Poppins, Helvetica, Arial, sans-serif',
	$button: mat.m2-define-typography-level(14px,14px,600,$letter-spacing: 0.5px)
);

/**
 * Utilisation de la font personnalisée
 */
@include mat.all-component-typographies($notilus-app-typography);