.modal-actions-list {
	margin-bottom: 6px;

	ul {
		list-style: none;
		padding: 0;
		margin: 0;

		.action-item {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 20px;
			padding: 0 20px;
			border-radius: 6px;

			&:hover {
				background-color: $cds-color-background-primary-light
			}

			.action-icon {
				color: $cds-color-text-primary-high-emphasis;
			}

			.action-label {
				display: flex;
				flex: 1;
				justify-content: space-between;
				align-items: center;
				@include typography(body-1);
			}
		}
	}
}