@import 'codemirror/lib/codemirror.css';
@import 'codemirror/theme/cobalt.css';
@import 'codemirror/addon/fold/foldgutter.css';
@import 'codemirror/addon/lint/lint.css';

/**
 * Import des fonts
 */
@import '@cegid/fonts';

/**
 * Style de l'application
 */
@import 'app/styles/app.scss';