complex-page {
	complex-page-info, form-customization, complex-page-actions {
		> .card {
			background-color: $cds-color-background-white-main;
			border-radius: 6px;

			.card-header {
				padding: 12px 16px !important;
				display: flex;
				justify-content: space-between;
				align-items: center;

				h2 {
					color: $cds-color-text-neutral-high-emphasis;
					@include typography(subtitle-1);
				}

				.actions {
					position: relative;
					right: auto;
					top: auto;
					z-index: auto;

					a {
						color: $cds-color-action-neutral-inactive;
					}
				}
			}

			.card-body {
				padding: 16px;
				padding-top: 0px;
				display: flex;
				flex-direction: column;

				.view-group {
					border-bottom: 1px solid $cds-color-border-neutral-decorative;
					margin: 2px 0 8px 0;

					label {
						color: $cds-color-text-neutral-medium-emphasis;
						@include typography(body-1);
						font-size: $n-label-input-font-size;
						line-height: 22px;
						margin-bottom: 0;
					}

					> div {
						color: $cds-color-text-neutral-high-emphasis;
						@include typography(body-1);
						font-size: $n-label-input-font-size;
						line-height: 22px;
					}
				}

				button {
					margin-top: 16px;
					align-self: flex-end;
				}
			}

			&.liste-actions {
				padding: 16px;

				.card-body {
					flex: 1;
					padding: 0;

					& > ul {
						list-style: none;
						padding: 0;
						margin: 0;

						& > li {
							display: flex;
							justify-content: space-between;
							cursor: pointer;
							align-items: center;
							height: 32px;
							color: $cds-color-text-neutral-high-emphasis;
							@include typography(body-2);
							font-size: $n-label-input-font-size;

							&:hover {
								color: var(--theme-primary-500);
							}

							.actions {
								i {
									color: $cds-color-action-neutral-inactive;
								}
							}
						}
					}
				}
			}
		}
	}
}