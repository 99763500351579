page-content {
	.card {
		.card-body {
			h4 {
				@include typography(headline-6);
				font-size: 16px;
				color: $cds-color-text-neutral-medium-emphasis;
			}
		}

		&.full {
			.card-padding {
				padding-top: 12px;
				padding-bottom: 12px;
			}
		}
	}

	.container-fluid {
		> form {
			margin-bottom: 64px;
		}
	}

	&[mode="sub"] {
		form {
			background-color: $cds-color-background-white-main;
			border-color: 1px solid $cds-color-border-neutral-decorative;
			border-radius: 6px;
			padding: 16px;

			.card {
				.card-header {
					padding: 16px;

					h2.card-title {
						@include typography(headline-6);
						color: $cds-color-text-neutral-high-emphasis;
					}
				}

				.card-body {
					&.card-padding {
						padding: 16px;
					}
				}
			}
		}

		.dashboard {
			form {
				background-color: transparent;
			}
		}
	}
}

.container {
	& > form {
		background-color: $cds-color-background-white-main;
		border-color: 1px solid $cds-color-border-neutral-decorative;
		border-radius: 6px;
		padding: 16px;
	}
}