.global-actions {
	position: relative;
	background-color: $cds-color-background-neutral-light;
	padding-top: 60px;

	.global-actions-container {
		position: absolute;
		top: 0;
		width: 100%;
		border-bottom: 1px solid $cds-color-border-neutral-decorative;
		background-color: $cds-color-background-neutral-light;
		padding: 5px;
		z-index: 2;

		mat-icon {
			font-weight: bold;
		}
	}
}

body.scrolled {
	.global-actions {
		.global-actions-container {
			box-shadow: 0 2px 5px rgba(0,0,0,0.16), 0 2px 10px rgba(0,0,0,0.12);
			border-bottom: none;
		}
	}
}