.import-template {
	.field {
		span.required {
			&:after {
				content: '*';
				color: $cds-color-text-critical-high-emphasis;
				padding-left: 6px;
			}
		}
	}
}