.sortable-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid rgba($cds-color-border-neutral-decorative,0.1);

	& > div {
		display: flex;
		align-items: center;

		.handle {
			margin-left: 10px;
			margin-right: 10px;
		}
	}

	& > ul {
		list-style: none;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.sortable-list {
	min-height: 150px;
	max-height: 500px;
	overflow-y: scroll;

	.sortable-item {
		padding: 12px;
		cursor: pointer;
	}

	.sortable-icon {
		margin-left: 10px;
		margin-right: 10px;
	}
}