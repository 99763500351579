.login-content {
	background-color: $cds-color-background-primary-main;
	background-image: url('../../../assets/layout/signin.svg');
	background-repeat: no-repeat;
	background-position: -5% -5%;

	.view {
		margin: 0;
		height: 100%;

		> div {
			height: 100%;

			start-login {
				height: 100%;
			}
		}
	}

	.login-container {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;

		.login-card {
			display: relative;
			z-index: 10;
			border-radius: 32px;
			background-color: $cds-color-background-white-main;
			padding: 24px;
			min-width: 400px;
			max-width: 500px;
			overflow: hidden;

			mat-button-toggle-group,mat-button-toggle {
				width: 100%;
			}

			.logo {
				max-width: 100%;
				height: 32px;
			}

			h5.title {
				@include typography(headline-5);
				margin: 24px 0;
			}

			.federation {
				margin-bottom: 24px;
			}

			button {
				&:not(.mat-button-toggle-button):not(.dropdown-toggle) {
					margin-top: 8px;
					width: 100%;

					&.redirect {
						margin-top: 16px;
						width: 100%;

						.mat-button-wrapper {
							justify-content: center;
						}
					}
				}
			}

			.re-captcha {
				margin-top: 8px;
				margin-bottom: 8px;
				text-align: center;
			}
		}
	}
}

ul.password-rules {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 24px;
	list-style: none;

	> li {
		flex: 50%;

		&:before {
			content: '\f136';
			font-family: 'Material-Design-Iconic-Font';
			position: absolute;
			margin-left: -20px;
			line-height: 1.6em;
			color: $cds-color-action-critical-main-active;
		}

		&.valid {
			&:before {
				content: '\f26b';
				color: $cds-color-background-success-main;
			}
		}
	}
}

.password-icon {
	color: $cds-color-action-critical-main-active;

	&.success {
		color: $cds-color-background-success-main;
	}
}

@media (max-width: $cds-size-breakpoint-sm) {
	.login-content {
		background-image: none;
	}
}