.icon-hc-lg > i, i.icon-hc-lg {
	font-size: 1.33333333em;
	line-height: 0.75em;
	vertical-align: -15%;
}

.icon-hc-1-dot-6x > i, i.icon-hc-1-dot-6x {
	font-size: 1.6em;
}

@for $i from 2 to 76 {
	.icon-hc-#{$i}x > i, i.icon-hc-#{$i}x {
		font-size: #{$i}em;
	}
}

.icon-hc-stack-1x > i, .icon-hc-stack-2x > i {
	position: absolute;
	left: 0;
	width: 100%;
	text-align: center;
}

.icon-hc-stack-2x > i {
	z-index: 1;
	font-size: 2em;
}

.icon-hc-stack-1x > i {
	z-index: 2;
	font-size: 1em;
}

.icon-hc-rotate-90 > i {
	transform: rotate(90deg);
}

.icon-hc-fw > i {
	width: 1.28571429em;
	text-align: center;
}

.icon-hc-stack {
	line-height: 0px;
}

.icon-hc-inverse {
	i {
		color: var(--theme-primary-50);
	}
}

.icon-pulse:hover {
	i {
		animation: wave .5s ease-in-out alternate infinite;
	}
}