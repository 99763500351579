mat-radio-group {
	mat-radio-button {
		line-height: 40px;

		.mdc-label {
			line-height: 40px;
			padding: 0;
			margin: 0;
			font-weight: unset;
			cursor: pointer;
		}
	}
}