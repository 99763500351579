basket {
	.form-group {
		> div {
			padding-top: 4px;
			padding-bottom: 4px;
			min-height: 45px;

			span.empty {
				@include typography(subtitle-1);
				color: $cds-color-text-neutral-medium-emphasis;
				line-height: 45px;
			}
		}
	}
}