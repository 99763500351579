.regle-taxe {
	.taxe-line {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 10px;

		.form-group {
			margin-bottom: 0;
			flex-basis: 25%;
		}

		.genre {
			flex: 2;
		}

		.action {
			padding-top: 20px;
		}

		&:not(:last-child) {
			margin-bottom: 20px;
		}
	}
}