header {
	mat-toolbar {
		position: fixed;
		width: 100%;
		margin-top: 65px;
		padding-left: 30px;
		padding-right: 30px;
		z-index: 10;
		background-color: $cds-color-background-white-main !important;
		border-bottom: 1px solid $cds-color-border-neutral-decorative;
		@include transition(box-shadow);
		@include transition-duration(300ms);
		justify-content: space-between;

		> .left-container,> .middle-container,> .right-container {
			display: flex;
			flex: 1;
			justify-content: center;
		}

		> .left-container {
			justify-content: flex-start;
			align-items: center;

			> button {
				margin-right: 16px;
			}

			.logo {
				background-size: contain;
				height: 32px;
				width: 100%;
				background-repeat: no-repeat;
			}
		}

		> .right-container {
			justify-content: flex-end;
		}
	}

	.magicsearch {
		background-color: white;
		box-shadow: 0 2px 2px rgba(0,0,0,0.16);
		margin-top: 0;

		ul.liste_search {
			height: 45px;
		}
	}
}

header-tools {
	ul.tools {
		padding: 8px;
		list-style: none;
		margin: 0px;
		display: flex;
		flex-direction: column;
		width: 350px;

		li {
			border-radius: 6px;
			width: 100%;
			display: flex;
			align-items: center;
			padding: 14px 16px;
			text-align: left;
			cursor: pointer;

			&:hover {
				background-color: $cds-color-interaction-neutral-hovered;
			}

			span {
				color: $cds-color-action-neutral-active;
				margin-left: 16px;

				@include typography(subtitle-1);
				letter-spacing: 0.5px;
			}

			icon {
				font-size: xx-large;
				color: $cds-color-text-link-neutral-resting;
			}
		}
	}
}

#sidebar {
 	z-index: 9;

	.main-menu {
		& > li.sub-menu {
			ul {
				& > li ul {
					margin: 0;
				}
			}
		}
	}

	&:not(.toggled) {
		left: -300px;
	}

	&.toggled {
		left: 0px;
	}
}

body {
	&.scrolled {
		header {
			mat-toolbar {
				box-shadow: rgba(0,29,54,0.08) 0px 8px 10px 1px, rgba(0,29,54,0.12) 0px 3px 14px 2px, rgba(0,29,54,0.12) 0px 5px 5px -3px;
			}
		}
	}

	&.menu-opened {
		header {
			.title-container {
				padding-left: 300px;
			}
		}

	}
}

header-progress {
	.progress-item {
		width: 350px;
		display: flex;
		flex-direction: row;
		padding: 10px;
		position: relative;
		border-radius: 4px;
		margin: 4px;
		gap: 2px;

		&:hover {
			background-color: $cds-color-interaction-neutral-hovered;
		}

		.icon-container {
			display: flex;
			font-size: 36px;
			color: $cds-color-text-link-neutral-resting;
		}

		.content-container {
			flex: 1;
			display: flex;
			flex-direction: column;
			overflow: hidden;

			.content-top {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				flex: 1;

				.label {
					display: flex;
					gap: 4px;
					align-items: center;
				}

				.libelle {
					font-size: 13px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					flex: 1;
					text-align: left;
				}

				span.libelle {
					color: $cds-color-text-link-neutral-resting;
				}

				&:not(:hover) {
					.hovered-item {
						display: none;
						color: $cds-color-text-link-neutral-resting;
					}
				}
			}

			.progress {
				width: 100%;
				margin-top: 10px;
			}
		}
	}
}