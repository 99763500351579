:root {
	--mat-standard-button-toggle-label-text-size: $n-label-input-font-size;
}

mat-icon {
	font-size: 24px !important;
	height: 24px !important;
	width: 24px !important;
	line-height: 1 !important;
}

mat-form-field {
	[matSuffix], [matIconSuffix], [matTextSuffix] {
		padding-left: 4px;
	}
}

.mat-primary, .mat-accent {
	--mdc-protected-button-label-text-color: var(--theme-primary-A100) !important;
	--mdc-filled-button-label-text-color: var(--theme-primary-A100) !important;
	--mdc-checkbox-selected-checkmark-color: var(--theme-primary-A100) !important;

	> mat-icon {
		--mat-icon-color: var(--theme-primary-A100) !important;
	}
}

.mat-mdc-menu-panel.mat-mdc-menu-panel {
	min-width: unset !important;
	max-width: unset !important;

	.mat-mdc-menu-content {
		line-height: unset;
		font-size: unset;
		padding: 0;
	}
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
	line-height: 32px !important;
}

.intl-tel-input {
	width: 100%;

	.selected-flag {
		z-index: 2;
	}

	.country-list {
		z-index: 3;
	}

	input[type="text"]:focus {
		padding-left: 47px;
	}
}

.progress-bar {
	background-color: var(--theme-primary-700);
}

.mat-mdc-checkbox {
	label {
		color: $cds-color-text-neutral-medium-emphasis;
		@include typography(label);
		font-size: $n-label-input-font-size;
		margin-bottom: 0;
		padding-left: 0;
	}
}

.table-hover {
	& > tbody {
		& > tr {
			&:hover {
				background-color: rgba(var(--theme-primary-700_rgb),0.2) !important;
			}
		}
	}
}

.mat-form-field-appearance-outline .mat-form-field-suffix {
	top: inherit !important;
	margin: 0 4px;
}

.mdc-button, .mat-mdc-outlined-button {
	padding: 0 16px !important;
}