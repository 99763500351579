.list-group {
	.list-group-item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		line-height: $cds-typography-subtitle-2-line-height;

		> mat-checkbox {
			height: 22px;
			width: 22px;
			display: flex;
			align-items: center;
			left: -11px;
			margin-left: 6px;
			margin-right: 8px;

			label {
				padding-left: 0;
				margin: 0;
			}
		}

		> span {
			flex: 1;
			display: flex;
			align-items: center;
			overflow: hidden;

			&.checkbox-padding {
				padding-left: 38px;
			}

			> a {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
}