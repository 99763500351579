.dashboard-custom-container {
	display: flex;
	flex-direction: column;
	gap: 5px;

	.section {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 5px;

		.action {
			font-size: 20px;
		}
	}

	.rule {
		.rule-detail {
			padding: 0;
		}

		.color-picker-field {
			margin-bottom: 16px;
		}
	}
}