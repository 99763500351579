/**
 * Palettes générées côté backend par le ThemeService à partir de leur couleur hexa '500'
 */
:root {
	--theme-primary-50: #E0E9FF;
	--theme-primary-50_rgb: 224,233,255;
	--theme-primary-100: #B3C8FF;
	--theme-primary-100_rgb: 179,200,255;
	--theme-primary-200: #80A3FF;
	--theme-primary-200_rgb: 128,163,255;
	--theme-primary-300: #4D7EFE;
	--theme-primary-300_rgb: 77,126,254;
	--theme-primary-400: #2662FE;
	--theme-primary-400_rgb: 38,98,254;
	--theme-primary-500: #0046FE;
	--theme-primary-500_rgb: 0,70,254;
	--theme-primary-600: #003FFE;
	--theme-primary-600_rgb: 0,63,254;
	--theme-primary-700: #0037FE;
	--theme-primary-700_rgb: 0,55,254;
	--theme-primary-800: #002FFE;
	--theme-primary-800_rgb: 0,47,254;
	--theme-primary-900: #0020FD;
	--theme-primary-900_rgb: 0,32,253;
	--theme-primary-A100: #FFFFFF;
	--theme-primary-A100_rgb: 255,255,255;
	--theme-primary-A200: #F0F2FF;
	--theme-primary-A200_rgb: 240,242,255;
	--theme-primary-A400: #BEC2FF;
	--theme-primary-A400_rgb: 190,194,255;
	--theme-primary-A700: #A4ABFF;
	--theme-primary-A700_rgb: 164,171,255;

	--theme-accent-50: #F9E9E5;
	--theme-accent-50_rgb: 249,233,229;
	--theme-accent-100: #F0C8BF;
	--theme-accent-100_rgb: 240,200,191;
	--theme-accent-200: #E6A495;
	--theme-accent-200_rgb: 230,164,149;
	--theme-accent-300: #DB806A;
	--theme-accent-300_rgb: 219,128,106;
	--theme-accent-400: #D4644A;
	--theme-accent-400_rgb: 212,100,74;
	--theme-accent-500: #CC492A;
	--theme-accent-500_rgb: 204,73,42;
	--theme-accent-600: #C74225;
	--theme-accent-600_rgb: 199,66,37;
	--theme-accent-700: #C03920;
	--theme-accent-700_rgb: 192,57,32;
	--theme-accent-800: #B9311A;
	--theme-accent-800_rgb: 185,49,26;
	--theme-accent-900: #AD2210;
	--theme-accent-900_rgb: 173,34,16;
	--theme-accent-A100: #FFE1DE;
	--theme-accent-A100_rgb: 255,225,222;
	--theme-accent-A200: #FFB2AB;
	--theme-accent-A200_rgb: 255,178,171;
	--theme-accent-A400: #FF8478;
	--theme-accent-A400_rgb: 255,132,120;
	--theme-accent-A700: #FF6C5E;
	--theme-accent-A700_rgb: 255,108,94;
}