.stepper {
	list-style: none;
	width: 100%;
	position: relative;
	padding-left: 0;
	display: flex;

	.step {
		position: relative;
		text-align: left;
		padding: 8px;
		flex: 1;
		cursor: pointer;

		&:before, &:after {
			position: absolute;
			content: ' ';
			top: 6px;
			width: calc(50% - 20px);
			height: 2px;
			background-color: rgba(var(--theme-primary-500_rgb),.5);
		}

		&:after {
			right: 0;
		}

		&:before {
			left: 0;
		}

		&:first-child {
			&:before {
				display: none;
			}
		}

		&:last-child {
			&:after {
				display: none;
			}
		}

		.content {
			position: inherit;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			height: 24px;
			line-height: 21px;
			z-index: 1;
			white-space: nowrap;

			& > span {
				color: $cds-color-text-neutral-disabled;
			}

			.marker {
				text-align: center;
				display: inline-block;
				position: relative;
				font-weight: bold;
				width: 24px;
				height: 24px;
				border-radius: 50%;
				margin-bottom: 5px;
				border: 2px solid rgba(var(--theme-primary-500_rgb),.5);
				color: rgba(var(--theme-primary-500_rgb),.5);
			}
		}

		&.active, &.done {
			&:before, &:after {
				background-color: var(--theme-primary-500);
			}

			.content {
				color: var(--theme-primary-500);

				.marker {
					border-color: var(--theme-primary-500);
					background-color: var(--theme-primary-500);
					color: $cds-color-text-white-high-emphasis;
				}
			}
		}

		&.active {
			.content {
				& > span {
					color: var(--theme-primary-500);
				}

				.marker {
					background-color: $cds-color-text-white-high-emphasis;
				}
			}
		}

		&.done {
			.content {
				.marker {
					&:before {
						content: '\f26b';
						position: absolute;
						font-family: 'Material-Design-Iconic-Font';
						border-radius: 50%;
						color: $cds-color-text-white-high-emphasis;
					}

					display: flex;
					align-items: center;
					justify-content: center;
					color: transparent;
				}
			}
		}
	}
}