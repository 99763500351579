.progress-modal {
	.log {
		background-color: $cds-color-background-primary-light;
		padding: 10px;
		margin-top: 20px;
		color: $cds-color-text-neutral-medium-emphasis;
		overflow-y: scroll;
		@include typography(body-1);
		max-height: 70vh;
		border-radius: 4px;

		ul {
			list-style: none;
			padding: 0;
		}
	}
}
