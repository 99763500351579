.alert {
	border-radius: 6px;
	border-width: 0px;
	border-left: 6px solid;
	@include typography(body-2);
	display: flex;
	flex-direction: row;
	padding-right: 10px !important;
	gap: 10px;
	padding: 5px 10px;

	& > icon,& > button {
		line-height: 36px;
		max-height: 36px;
	}

	& > div,& > strong {
		padding-top: 8px;
		flex: 1;
	}

	i {
		font-size: 2em;
	}

	&.alert-default {
		&:not(.alert-info):not(.alert-warning):not(.alert-danger) {
			box-shadow: 0 1px 1px rgba(0,0,0,0.15);
		}

		i {
			font-size: 2em;
		}
	}

	> div.no-break {
		display: inline-block;
	}

	&.alert-no-shadow {
		box-shadow: none;
	}
}