.article {
	.actions-container {
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		height: 70px;

		[data-counter]:after {
			content: attr(data-counter);
			position: absolute;
			color: $cds-color-action-white-active;
			font-style: normal;
			background: var(--theme-primary-400);
			padding: 1px 4px;
			border-radius: 2px;
			top: -6px;
			left: 24px;
			font-size: 12px;
			line-height: 16px;
		}
	}

	button[mat-icon-button] {
		padding: 0;
		display: flex;
		text-align: center;

		.mat-icon {
			font-size: 42px !important;
			line-height: 42px !important;
			height: 42px !important;
			width: 42px !important;
		}
	}

	.article-content {
		overflow: hidden;

		table {
			max-width: 100%;
		}
	}
}