@use 'sass:map';

@mixin transition($transition) {
	transition: $transition;
	-webkit-transition: $transition;
	-o-transition: $transition;
}

@mixin transition-duration($transition-duration) {
	transition-duration: $transition-duration;
	-webkit-transition-duration: $transition-duration;
}

@mixin typography($mode,$important: null) {
	font-family: map.get(map.get($typographies,$mode),'font-family') $important;
	font-size: map.get(map.get($typographies,$mode),'font-size') $important;
	font-weight: map.get(map.get($typographies,$mode),'font-weight') $important;
	line-height: map.get(map.get($typographies,$mode),'line-height') $important;
	letter-spacing: map.get(map.get($typographies,$mode),'letter-spacing') $important;
}