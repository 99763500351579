.listview {
	&.no-search {
		padding-top: 24px;
	}

	.lv-header-alt {
		padding-left: 0;
		padding-right: 0;

		.title-container {
			button[mat-icon-button] {
				line-height: 32px !important;

				.mat-icon {
					font-size: 32px !important;
					line-height: 32px !important;
					height: 32px !important;
					width: 32px !important;
					overflow: unset;
				}

				mat-icon {
					color: $cds-color-text-neutral-medium-emphasis;
				}
			}
		}
	}

	.lv-input {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		gap: 8px;
	}

	.title-container {
		> div {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
		}
	}

	[list-view-search] {
		.magicsearch {
			display: flex;
			width: 100%;
			border-bottom: 0;
			padding-bottom: 0;
			margin-top: 15px;

			form {
				display: flex;
				align-items: center;
				flex: 1;

				mat-form-field {
					width: 100%;

					.mat-mdc-form-field-icon-prefix {
						> mat-icon {
							padding: 0;
						}
					}

					.mat-mdc-text-field-wrapper {
						margin: 0;
						padding: 0;

						.mat-mdc-form-field-focus-overlay {
							display: none;
						}

						.mat-mdc-form-field-flex {
							border: 1px solid $cds-color-border-neutral-decorative;
							padding: 0 6px;
							background-color: $cds-color-background-white-main;
							border-radius: 6px;

							.mat-mdc-form-field-infix {
								border: 0;
								display: flex;
								align-items: center;
								padding: 0;
								min-height: 46px;

								input {
									min-width: 300px;
									font-size: $n-label-input-font-size;
									letter-spacing: normal;
								}
							}
						}
					}

					.mat-mdc-form-field-subscript-wrapper, .mdc-line-ripple {
						display: none;
					}
				}

				.pagination {
					margin: 0;
					display: flex;

					button {
						mat-icon {
							color: $cds-color-action-neutral-active;
						}

						&[disabled] {
							cursor: not-allowed;

							mat-icon {
								color: $cds-color-action-neutral-inactive;
							}
						}
					}
				}
			}
		}

		form.magicsearch-form {
			border: 1px solid $cds-color-border-neutral-decorative;
			background-color: $cds-color-background-white-main;
			border-radius: 6px;
			margin-top: 6px;
			max-height: calc(100vh - 210px);
			padding: 0 16px;

			.filter-separator {
				margin-bottom: 16px;
			}

			ul.liste_fields {
				list-style: none;
				padding: 0;
				margin-top: 8px;

				> li {
					float: left;
					margin-right: 4px;
					position: relative;

					a {
						display: block;
						clear: both;
						white-space: nowrap;
						padding: 8px 17px;
						border-bottom: 2px solid transparent;
						@include typography(button);

						&:hover,&.current {
							text-decoration: none;

							span {
								color: var(--theme-primary-500);
							}

							&:after {
								position: absolute;
								content: ' ';
								height: 2px;
								left: 0;
								bottom: 0;
								width: 100%;
								background-color: var(--theme-primary-500);
								border-top-left-radius: 2px;
								border-top-right-radius: 2px;
							}
						}

						span {
							&.default {
								&:after {
									content: '*';
								}
							}

							&:not(.default) {
								font-weight: normal;
							}
						}
					}
				}
			}

			.magicsearch-form-action {
				position: sticky;
				bottom: 0;
				background-color: $cds-color-background-white-main;

				.mat-mdc-checkbox {
					label {
						font-weight: 700;
					}
				}
			}
		}
	}

	&.local {
		[list-view-search] {
			.magicsearch {
				margin-top: 0;
			}
		}
	}
}