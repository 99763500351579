.workflow {
	overflow-x: auto;
	background-color: $cds-color-background-neutral-light;
	border-radius: 6px;
	padding-top: 50px;

	a.disabled {
		cursor: not-allowed !important;
	}

	table {
		width: 100%;
		height: 100%;
		border-collapse: separate;

		&.initial {
			height: auto !important;
		}
	}

	> table {
		position: relative;
	}

	* {
		margin: 0;
		padding: 0;
		border: 0;
		font-size: 100%;
		vertical-align: baseline;
	}

	*, *:before, *:after {
		box-sizing: inherit;
	}

	tbody {
		padding: 0;
		margin: 0;
		border: 0;
		font-size: 100%;
		vertical-align: baseline;
	}

	td {
		text-align: center;
		vertical-align: top;
		border-radius: 0px;

		&.node {
			padding-right: 5px;
			padding-left: 5px;
		}

		&.button-container {
			height: 0px;
			position: relative;
		}
	}

	tr.lines {
		td {
			&.startline, &.branchline {
				width: 1px;
				height: 30px;
			}

			&.line-left {
				border-right: 1px solid $cds-color-border-neutral-medium-emphasis;
			}

			&.line-right {
				border-left: 1px solid $cds-color-border-neutral-medium-emphasis;
			}

			&.line-top {
				border-top: 2px solid $cds-color-border-neutral-medium-emphasis;
			}

			&.line-bottom {
				border-bottom: 2px solid $cds-color-border-neutral-medium-emphasis;
			}

			div.counter {
				&:before {
					content: ' ';
					position: absolute;
					width: 20px;
					height: 20px;
					background-color: $cds-color-action-primary-active;
					margin-left: -10px;
					margin-top: -10px;
					transform: rotate(45deg);
				}

				span {
					position: absolute;
					color: $cds-color-text-white-high-emphasis;
					width: 30px;
					height: 30px;
					line-height: 30px;
					margin-left: -15px;
					margin-top: -15px;
				}
			}
		}
	}

	tr.extend {
		height: 100%;

		td.line {
			&.line-left {
				border-right: 1px solid $cds-color-border-neutral-medium-emphasis;
			}

			&.line-right {
				border-left: 1px solid $cds-color-border-neutral-medium-emphasis;
			}
		}
	}

	tr.statut-container {
		> td {
			position: relative;

			div.statut {
				position: absolute;
				cursor: pointer;
				text-align: right;
				font-weight: bold;
				padding-right: 16px;
				border-bottom: 2px dashed var(--theme-primary-700);
				width: 100%;
				color: var(--theme-primary-700);
				text-transform: uppercase;
				margin-top: -20px;
			}
		}
	}

	div {
		&.node {
			position: relative;

			.content {
				width: 200px;
				margin: 0 auto;
				text-align: left;
				padding: 5px;
				padding-bottom: 15px;
				position: relative;

				div.title {
					padding: 0;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					position: relative;

					input {
						font-size: 10px;
					}
				}

				ul.actions {
					li > a {
						margin-top: 0;

						& > icon > i {
							margin-right: 0;
							line-height: inherit;
							color: $cds-color-action-neutral-inactive;
						}
					}
				}
			}

			&.start, &.end {
				.content {
					background-color: $cds-color-background-white-main;
					border: 2px solid $cds-color-border-neutral-high-emphasis;
					border-radius: 6px;
					width: 300px;

					div.title {
						color: $cds-color-text-neutral-high-emphasis;
						font-weight: bold;
						text-align: center;

						&:after {
							display: none;
						}
					}
				}
			}


			&.etape .content {
				background-color: $cds-color-background-white-main;
				border: 1px solid $cds-color-border-neutral-medium-emphasis;
				border-radius: 6px;

				div.title {
					color: $cds-color-text-neutral-high-emphasis;
					overflow: visible;
				}

				div.extra-content {
					padding: 3px;
					position: relative;
					margin-bottom: -15px;

					.counter {
						position: relative;
						width: 30px;
						height: 30px;
						border-radius: 50%;
						background-color: $cds-color-background-info-light;

						span {
							position: absolute;
							color: $cds-color-text-neutral-medium-emphasis;
							line-height: 30px;
							width: 30px;
							text-align: center;
							left: 0;
						}
					}
				}
			}

			&.branch .content {
				background-color: $cds-color-background-primary-main;
				border-radius: 6px;

				div.title {
					color: $cds-color-text-white-high-emphasis;
					overflow: visible;

					&:after {
						background-color: $cds-color-background-white-main;
					}

					input {
						color: $cds-color-text-white-high-emphasis !important;
						-webkit-text-fill-color: $cds-color-text-white-high-emphasis !important;
						border-color: $cds-color-text-white-high-emphasis !important;
					}
				}

				ul.actions {
					a i, a icon > i {
						color: $cds-color-action-white-active;
					}
				}
			}

			&.branch.no-response .content {
				opacity: 0.6;

				.title {
					padding-top: 10px;
					padding-bottom: 11px;
					font-size: 10px;
				}
			}

			.collapse-container {
				position: absolute;
				background-color: $cds-color-background-white-main;
				cursor: pointer;
				width: 20px;
				height: 20px;
				text-align: center;
				border-radius: 50%;
				border: 1px solid $cds-color-border-neutral-medium-emphasis;
				left: 50%;
				margin-left: -10px;
				bottom: -10px;

				i, icon > i {
					color: $cds-color-action-primary-active;
					font-weight: bold;
				}
			}

			&.has-error {
				&.branch > .content {
					background-color: $cds-color-background-critical-main;
				}
			}

			&.has-error {
				&.etape > .content {
					border-color: $cds-color-border-critical-main-high-emphasis;
				}
			}
		}
	}

	&.small {
		div.node.etape, div.node.branch {
			.content {
				width: 120px;

				div.extra-content {
					.counter {
						width: 20px;
						height: 20px;

						span {
							line-height: 20px;
							width: 20px;
						}
					}
				}
			}
		}
	}

	.menu {
		transition: all 0.3s ease-in;
		height: 0px;
		display: none;

		&:before {
			content: ' ';
			position: absolute;
			width: 2px;
			margin-left: -1px;
			height: 100%;
			background-color: $cds-color-border-neutral-medium-emphasis;
		}

		.button {
			position: absolute;
			background-color: $cds-color-action-neutral-active;
			cursor: pointer;
			width: 20px;
			height: 20px;
			text-align: center;
			border-radius: 50%;
			border: 1px solid $cds-color-border-neutral-medium-emphasis;
			left: 50%;
			margin-left: -10px;
			transition: all 0.3s ease-in;
			line-height: 18px;
		}

		&.branch {
			.button {
				background-color: $cds-color-action-primary-active;
				border-radius: 0;
				transform: rotate(45deg);
			}

			&:hover {
				.button {
					border-radius: 50%;
					transform: rotate(135deg);
				}
			}
		}

		div.button {
			top: -10px;
			z-index: auto;
		}

		&.opened {
			height: 60px;

			div.button {
				top: 15px;
			}

			> div > i,> div > icon > i {
				transform: rotate(45deg);
			}

			div.action-container {
				a {
					opacity: 1;
					pointer-events: all;

					&:nth-child(1) {
						transform: translate3d(0px,50px,0);
					}

					&:nth-child(2) {
						transform: translate3d(-30px,-10px,0);
					}

					&:nth-child(3) {
						transform: translate3d(30px,-10px,0);
					}
				}
			}
		}

		i, icon > i {
			transition: all 0.3s ease-in;
			color: $cds-color-text-white-high-emphasis;
			font-weight: bold;
		}

		div.action-container {
			a {
				background-color: $cds-color-action-primary-active;
				opacity: 0;
				width: 26px;
				height: 26px;
				margin-left: -13px;
				transition: transform ease-out 200ms;
				line-height: 24px;
				pointer-events: none;

				i, icon > i {
					color: $cds-color-action-white-active;
				}

				&:nth-child(1) {
					transition-duration: 450ms;
				}

				&:nth-child(2) {
					transition-duration: 300ms;
				}

				&:nth-child(3) {
					transition-duration: 150ms;
				}
			}
		}
	}

	&.edition {
		.menu {
			display: block;
		}
	}
}