
/**
 * Do not edit directly
 * Generated on Wed, 06 Dec 2023 16:24:31 GMT
 */

$cds-color-action-neutral-active: #002C52 !default;
$cds-color-action-neutral-inactive: #002C52A3 !default;
$cds-color-action-neutral-disabled: #002C5252 !default;
$cds-color-action-primary-active: #0046FE !default;
$cds-color-action-primary-disabled: #0046FE52 !default;
$cds-color-action-secondary-active: #CC492A !default;
$cds-color-action-secondary-disabled: #CC492A52 !default;
$cds-color-action-white-active: #FFFFFF !default;
$cds-color-action-white-inactive: #FFFFFFA3 !default;
$cds-color-action-white-disabled: #FFFFFF52 !default;
$cds-color-action-info-dark-active: #155AC1 !default;
$cds-color-action-info-dark-disabled: #155AC152 !default;
$cds-color-action-critical-main-active: #FF525D !default;
$cds-color-action-critical-main-disabled: #FF525D52 !default;
$cds-color-action-critical-dark-active: #BC0E2E !default;
$cds-color-action-critical-dark-disabled: #BC0E2E52 !default;
$cds-color-action-warn-dark-active: #8B5000 !default;
$cds-color-action-warn-dark-disabled: #8B500052 !default;
$cds-color-action-success-dark-active: #376A1F !default;
$cds-color-action-success-dark-disabled: #376A1F52 !default;
$cds-color-background-neutral-main: #002C52 !default;
$cds-color-background-neutral-main-disabled: #002C5252 !default;
$cds-color-background-neutral-light: #F7F8F9 !default;
$cds-color-background-primary-main: #0046FE !default;
$cds-color-background-primary-main-disabled: #0046FE52 !default;
$cds-color-background-primary-light: #F7F9FE !default;
$cds-color-background-secondary-main: #CC492A !default;
$cds-color-background-secondary-main-disabled: #CC492A52 !default;
$cds-color-background-secondary-light: #FCF7F6 !default;
$cds-color-background-info-main: #598EF8 !default;
$cds-color-background-info-main-disabled: #598EF852 !default;
$cds-color-background-info-light: #EDF0FF !default;
$cds-color-background-info-dark: #155AC1 !default;
$cds-color-background-info-dark-disabled: #155AC152 !default;
$cds-color-background-critical-main: #FF525D !default;
$cds-color-background-critical-main-disabled: #FF525D52 !default;
$cds-color-background-critical-light: #FFEDEC !default;
$cds-color-background-critical-dark: #BC0E2E !default;
$cds-color-background-critical-dark-disabled: #BC0E2E52 !default;
$cds-color-background-warn-main: #D27C00 !default;
$cds-color-background-warn-main-disabled: #D27C0052 !default;
$cds-color-background-warn-light: #FFEEE1 !default;
$cds-color-background-warn-dark: #8B5000 !default;
$cds-color-background-warn-dark-disabled: #8B500052 !default;
$cds-color-background-success-main: #689F4D !default;
$cds-color-background-success-main-disabled: #689F4D52 !default;
$cds-color-background-success-light: #E3FFD0 !default;
$cds-color-background-success-dark: #376A1F !default;
$cds-color-background-success-dark-disabled: #376A1F52 !default;
$cds-color-background-white-main: #FFFFFF !default;
$cds-color-background-white-main-disabled: #FFFFFF52 !default;
$cds-color-border-neutral-high-emphasis: #002C52 !default;
$cds-color-border-neutral-medium-emphasis: #002C52A3 !default;
$cds-color-border-neutral-disabled: #002C5252 !default;
$cds-color-border-neutral-decorative: #002C5233 !default;
$cds-color-border-primary-high-emphasis: #0046FE !default;
$cds-color-border-primary-disabled: #0046FE52 !default;
$cds-color-border-white-high-emphasis: #FFFFFF !default;
$cds-color-border-white-medium-emphasis: #FFFFFFA3 !default;
$cds-color-border-white-disabled: #FFFFFF52 !default;
$cds-color-border-critical-main-high-emphasis: #FF525D !default;
$cds-color-border-critical-main-disabled: #FF525D52 !default;
$cds-color-border-critical-dark-high-emphasis: #BC0E2E !default;
$cds-color-border-critical-dark-disabled: #BC0E2E52 !default;
$cds-color-brand-primary-0: #000000 !default;
$cds-color-brand-primary-10: #000E33 !default;
$cds-color-brand-primary-20: #001C66 !default;
$cds-color-brand-primary-30: #002EAA !default;
$cds-color-brand-primary-40: #0038CC !default;
$cds-color-brand-primary-50: #0046FE !default;
$cds-color-brand-primary-60: #336BFF !default;
$cds-color-brand-primary-70: #6690FF !default;
$cds-color-brand-primary-80: #99B5FF !default;
$cds-color-brand-primary-90: #CCDAFF !default;
$cds-color-brand-primary-95: #E5EDFF !default;
$cds-color-brand-primary-99: #F7F9FE !default;
$cds-color-brand-primary-100: #FFFFFF !default;
$cds-color-brand-secondary-0: #000000 !default;
$cds-color-brand-secondary-10: #88301C !default;
$cds-color-brand-secondary-20: #B13F24 !default;
$cds-color-brand-secondary-30: #CC492A !default;
$cds-color-brand-secondary-40: #E6502C !default;
$cds-color-brand-secondary-50: #FF5C35 !default;
$cds-color-brand-secondary-60: #FF8466 !default;
$cds-color-brand-secondary-70: #FEAE99 !default;
$cds-color-brand-secondary-80: #FED6CC !default;
$cds-color-brand-secondary-90: #FEEAE5 !default;
$cds-color-brand-secondary-95: #FBF2F0 !default;
$cds-color-brand-secondary-99: #FCF7F6 !default;
$cds-color-brand-secondary-100: #FFFFFF !default;
$cds-color-brand-neutral-0: #000000 !default;
$cds-color-brand-neutral-10: #001D36 !default;
$cds-color-brand-neutral-20: #002C52 !default;
$cds-color-brand-neutral-30: #1A4163 !default;
$cds-color-brand-neutral-40: #335674 !default;
$cds-color-brand-neutral-50: #4D6B86 !default;
$cds-color-brand-neutral-60: #668097 !default;
$cds-color-brand-neutral-70: #8095A8 !default;
$cds-color-brand-neutral-80: #B3C0CB !default;
$cds-color-brand-neutral-90: #CCD4DC !default;
$cds-color-brand-neutral-95: #E6EAEE !default;
$cds-color-brand-neutral-99: #F7F8F9 !default;
$cds-color-brand-neutral-100: #FFFFFF !default;
$cds-color-brand-bananabread-0: #000000 !default;
$cds-color-brand-bananabread-10: #271900 !default;
$cds-color-brand-bananabread-20: #422C00 !default;
$cds-color-brand-bananabread-30: #5F4100 !default;
$cds-color-brand-bananabread-40: #7D5800 !default;
$cds-color-brand-bananabread-50: #9C6F02 !default;
$cds-color-brand-bananabread-60: #B98823 !default;
$cds-color-brand-bananabread-70: #D7A23D !default;
$cds-color-brand-bananabread-80: #FEC55C !default;
$cds-color-brand-bananabread-90: #FFDEA9 !default;
$cds-color-brand-bananabread-95: #FFEED8 !default;
$cds-color-brand-bananabread-99: #FFFBFF !default;
$cds-color-brand-bananabread-100: #FFFFFF !default;
$cds-color-brand-blackberry-0: #000000 !default;
$cds-color-brand-blackberry-10: #001C3A !default;
$cds-color-brand-blackberry-20: #00315F !default;
$cds-color-brand-blackberry-30: #004480 !default;
$cds-color-brand-blackberry-40: #2D5F9D !default;
$cds-color-brand-blackberry-50: #4979B8 !default;
$cds-color-brand-blackberry-60: #6492D3 !default;
$cds-color-brand-blackberry-70: #7FADF0 !default;
$cds-color-brand-blackberry-80: #A5C8FF !default;
$cds-color-brand-blackberry-90: #D4E3FF !default;
$cds-color-brand-blackberry-95: #EBF1FF !default;
$cds-color-brand-blackberry-99: #FDFCFF !default;
$cds-color-brand-blackberry-100: #FFFFFF !default;
$cds-color-brand-blueberry-0: #000000 !default;
$cds-color-brand-blueberry-10: #001B3D !default;
$cds-color-brand-blueberry-20: #003063 !default;
$cds-color-brand-blueberry-30: #00468C !default;
$cds-color-brand-blueberry-40: #005DB7 !default;
$cds-color-brand-blueberry-50: #0084FF !default;
$cds-color-brand-blueberry-60: #3C90FF !default;
$cds-color-brand-blueberry-70: #79ACFF !default;
$cds-color-brand-blueberry-80: #A9C7FF !default;
$cds-color-brand-blueberry-90: #D6E3FF !default;
$cds-color-brand-blueberry-95: #ECF0FF !default;
$cds-color-brand-blueberry-99: #FDFBFF !default;
$cds-color-brand-blueberry-100: #FFFFFF !default;
$cds-color-brand-gingerbread-0: #000000 !default;
$cds-color-brand-gingerbread-10: #331200 !default;
$cds-color-brand-gingerbread-20: #542200 !default;
$cds-color-brand-gingerbread-30: #773300 !default;
$cds-color-brand-gingerbread-40: #9C4500 !default;
$cds-color-brand-gingerbread-50: #BD5C19 !default;
$cds-color-brand-gingerbread-60: #E67B38 !default;
$cds-color-brand-gingerbread-70: #FE8E49 !default;
$cds-color-brand-gingerbread-80: #FFB68E !default;
$cds-color-brand-gingerbread-90: #FFDBCA !default;
$cds-color-brand-gingerbread-95: #FFEDE5 !default;
$cds-color-brand-gingerbread-99: #FFFBFF !default;
$cds-color-brand-gingerbread-100: #FFFFFF !default;
$cds-color-brand-lychee-0: #000000 !default;
$cds-color-brand-lychee-10: #2B1611 !default;
$cds-color-brand-lychee-20: #432A25 !default;
$cds-color-brand-lychee-30: #5B403A !default;
$cds-color-brand-lychee-40: #755751 !default;
$cds-color-brand-lychee-50: #907069 !default;
$cds-color-brand-lychee-60: #AB8982 !default;
$cds-color-brand-lychee-70: #C7A39B !default;
$cds-color-brand-lychee-80: #EDC6BE !default;
$cds-color-brand-lychee-90: #FFDAD3 !default;
$cds-color-brand-lychee-95: #FFEDE9 !default;
$cds-color-brand-lychee-99: #FFFBFF !default;
$cds-color-brand-lychee-100: #FFFFFF !default;
$cds-color-brand-plum-0: #000000 !default;
$cds-color-brand-plum-10: #001946 !default;
$cds-color-brand-plum-20: #152E61 !default;
$cds-color-brand-plum-30: #2E4579 !default;
$cds-color-brand-plum-40: #475D93 !default;
$cds-color-brand-plum-50: #6076AD !default;
$cds-color-brand-plum-60: #7A90C9 !default;
$cds-color-brand-plum-70: #95ABE6 !default;
$cds-color-brand-plum-80: #B1C5FF !default;
$cds-color-brand-plum-90: #DAE2FF !default;
$cds-color-brand-plum-95: #EEF0FF !default;
$cds-color-brand-plum-99: #FEFBFF !default;
$cds-color-brand-plum-100: #FFFFFF !default;
$cds-color-brand-tomato-0: #000000 !default;
$cds-color-brand-tomato-10: #3D0700 !default;
$cds-color-brand-tomato-20: #621100 !default;
$cds-color-brand-tomato-30: #8A1C00 !default;
$cds-color-brand-tomato-40: #B52701 !default;
$cds-color-brand-tomato-50: #D8411C !default;
$cds-color-brand-tomato-60: #FF5C35 !default;
$cds-color-brand-tomato-70: #FF8B6E !default;
$cds-color-brand-tomato-80: #FFB4A3 !default;
$cds-color-brand-tomato-90: #FFDAD2 !default;
$cds-color-brand-tomato-95: #FFEDE9 !default;
$cds-color-brand-tomato-99: #FFFBFF !default;
$cds-color-brand-tomato-100: #FFFFFF !default;
$cds-color-datav-viz-10: #E61A61 !default;
$cds-color-datav-viz-11: #6076AD !default;
$cds-color-datav-viz-12: #895EDB !default;
$cds-color-datav-viz-13: #475D93 !default;
$cds-color-datav-viz-14: #9CD67D !default;
$cds-color-datav-viz-15: #009FB4 !default;
$cds-color-datav-viz-16: #B98823 !default;
$cds-color-datav-viz-01: #336BFF !default;
$cds-color-datav-viz-02: #FEC55C !default;
$cds-color-datav-viz-03: #FF5C35 !default;
$cds-color-datav-viz-04: #002C52 !default;
$cds-color-datav-viz-05: #95ABE6 !default;
$cds-color-datav-viz-06: #EDC6BE !default;
$cds-color-datav-viz-07: #0084FF !default;
$cds-color-datav-viz-08: #FFB2BE !default;
$cds-color-datav-viz-09: #B13F24 !default;
$cds-color-extended-blue-0: #000000 !default;
$cds-color-extended-blue-10: #001944 !default;
$cds-color-extended-blue-20: #002D6D !default;
$cds-color-extended-blue-30: #004299 !default;
$cds-color-extended-blue-40: #155AC1 !default;
$cds-color-extended-blue-50: #3B73DC !default;
$cds-color-extended-blue-60: #598EF8 !default;
$cds-color-extended-blue-70: #84AAFF !default;
$cds-color-extended-blue-80: #AFC6FF !default;
$cds-color-extended-blue-90: #D9E2FF !default;
$cds-color-extended-blue-95: #EDF0FF !default;
$cds-color-extended-blue-99: #FEFBFF !default;
$cds-color-extended-blue-100: #FFFFFF !default;
$cds-color-extended-blue-grey-0: #000000 !default;
$cds-color-extended-blue-grey-10: #001F2A !default;
$cds-color-extended-blue-grey-20: #163440 !default;
$cds-color-extended-blue-grey-30: #2E4B57 !default;
$cds-color-extended-blue-grey-40: #466270 !default;
$cds-color-extended-blue-grey-50: #5E7B89 !default;
$cds-color-extended-blue-grey-60: #7895A4 !default;
$cds-color-extended-blue-grey-70: #92B0BF !default;
$cds-color-extended-blue-grey-80: #ADCBDB !default;
$cds-color-extended-blue-grey-90: #C9E7F7 !default;
$cds-color-extended-blue-grey-95: #E0F4FF !default;
$cds-color-extended-blue-grey-99: #FAFCFF !default;
$cds-color-extended-blue-grey-100: #FFFFFF !default;
$cds-color-extended-brown-0: #000000 !default;
$cds-color-extended-brown-10: #2E150B !default;
$cds-color-extended-brown-20: #46291E !default;
$cds-color-extended-brown-30: #603F33 !default;
$cds-color-extended-brown-40: #7A5649 !default;
$cds-color-extended-brown-50: #956E60 !default;
$cds-color-extended-brown-60: #B18779 !default;
$cds-color-extended-brown-70: #CEA192 !default;
$cds-color-extended-brown-80: #EBBCAC !default;
$cds-color-extended-brown-90: #FFDBCF !default;
$cds-color-extended-brown-95: #FFEDE7 !default;
$cds-color-extended-brown-99: #FFFBFF !default;
$cds-color-extended-brown-100: #FFFFFF !default;
$cds-color-extended-cyan-0: #000000 !default;
$cds-color-extended-cyan-10: #001F25 !default;
$cds-color-extended-cyan-20: #00363E !default;
$cds-color-extended-cyan-30: #004E59 !default;
$cds-color-extended-cyan-40: #006876 !default;
$cds-color-extended-cyan-50: #008394 !default;
$cds-color-extended-cyan-60: #009FB4 !default;
$cds-color-extended-cyan-70: #00BCD4 !default;
$cds-color-extended-cyan-80: #44D8F1 !default;
$cds-color-extended-cyan-90: #A1EFFF !default;
$cds-color-extended-cyan-95: #D4F7FF !default;
$cds-color-extended-cyan-99: #F7FDFF !default;
$cds-color-extended-cyan-100: #FFFFFF !default;
$cds-color-extended-deep-orange-0: #000000 !default;
$cds-color-extended-deep-orange-10: #3B0900 !default;
$cds-color-extended-deep-orange-20: #5F1500 !default;
$cds-color-extended-deep-orange-30: #862200 !default;
$cds-color-extended-deep-orange-40: #B02F00 !default;
$cds-color-extended-deep-orange-50: #DB3E03 !default;
$cds-color-extended-deep-orange-60: #FF5722 !default;
$cds-color-extended-deep-orange-70: #FF8B69 !default;
$cds-color-extended-deep-orange-80: #FFB5A0 !default;
$cds-color-extended-deep-orange-90: #FFDBD1 !default;
$cds-color-extended-deep-orange-95: #FFEDE8 !default;
$cds-color-extended-deep-orange-99: #FFFBFF !default;
$cds-color-extended-deep-orange-100: #FFFFFF !default;
$cds-color-extended-deep-purple-0: #000000 !default;
$cds-color-extended-deep-purple-10: #250059 !default;
$cds-color-extended-deep-purple-20: #3F008D !default;
$cds-color-extended-deep-purple-30: #5727A7 !default;
$cds-color-extended-deep-purple-40: #6F43C0 !default;
$cds-color-extended-deep-purple-50: #895EDB !default;
$cds-color-extended-deep-purple-60: #A378F8 !default;
$cds-color-extended-deep-purple-70: #BB99FF !default;
$cds-color-extended-deep-purple-80: #D3BBFF !default;
$cds-color-extended-deep-purple-90: #EBDDFF !default;
$cds-color-extended-deep-purple-95: #F7EDFF !default;
$cds-color-extended-deep-purple-99: #FFFBFF !default;
$cds-color-extended-deep-purple-100: #FFFFFF !default;
$cds-color-extended-green-0: #000000 !default;
$cds-color-extended-green-10: #072100 !default;
$cds-color-extended-green-20: #103900 !default;
$cds-color-extended-green-30: #1F5106 !default;
$cds-color-extended-green-40: #376A1F !default;
$cds-color-extended-green-50: #4F8436 !default;
$cds-color-extended-green-60: #689F4D !default;
$cds-color-extended-green-70: #81BA64 !default;
$cds-color-extended-green-80: #9CD67D !default;
$cds-color-extended-green-90: #B7F396 !default;
$cds-color-extended-green-95: #CDFFB0 !default;
$cds-color-extended-green-99: #F7FFEC !default;
$cds-color-extended-green-100: #FFFFFF !default;
$cds-color-extended-orange-0: #000000 !default;
$cds-color-extended-orange-10: #2C1600 !default;
$cds-color-extended-orange-20: #4A2800 !default;
$cds-color-extended-orange-30: #693C00 !default;
$cds-color-extended-orange-40: #8B5000 !default;
$cds-color-extended-orange-50: #AE6600 !default;
$cds-color-extended-orange-60: #D27C00 !default;
$cds-color-extended-orange-70: #F79300 !default;
$cds-color-extended-orange-80: #FFB870 !default;
$cds-color-extended-orange-90: #FFDCBE !default;
$cds-color-extended-orange-95: #FFEEE1 !default;
$cds-color-extended-orange-99: #FFFBFF !default;
$cds-color-extended-orange-100: #FFFFFF !default;
$cds-color-extended-pink-0: #000000 !default;
$cds-color-extended-pink-10: #400014 !default;
$cds-color-extended-pink-20: #660025 !default;
$cds-color-extended-pink-30: #900038 !default;
$cds-color-extended-pink-40: #BC004B !default;
$cds-color-extended-pink-50: #E61A61 !default;
$cds-color-extended-pink-60: #FF4E7C !default;
$cds-color-extended-pink-70: #FF869D !default;
$cds-color-extended-pink-80: #FFB2BE !default;
$cds-color-extended-pink-90: #FFD9DE !default;
$cds-color-extended-pink-95: #FFECEE !default;
$cds-color-extended-pink-99: #FFFBFF !default;
$cds-color-extended-pink-100: #FFFFFF !default;
$cds-color-extended-strawberry-0: #000000 !default;
$cds-color-extended-strawberry-10: #410008 !default;
$cds-color-extended-strawberry-20: #680013 !default;
$cds-color-extended-strawberry-30: #92001F !default;
$cds-color-extended-strawberry-40: #BC0E2E !default;
$cds-color-extended-strawberry-50: #E03043 !default;
$cds-color-extended-strawberry-60: #FF525D !default;
$cds-color-extended-strawberry-70: #FF8889 !default;
$cds-color-extended-strawberry-80: #FFB3B2 !default;
$cds-color-extended-strawberry-90: #FFDAD9 !default;
$cds-color-extended-strawberry-95: #FFEDEC !default;
$cds-color-extended-strawberry-99: #FFFBFF !default;
$cds-color-extended-strawberry-100: #FFFFFF !default;
$cds-color-extended-yellow-0: #000000 !default;
$cds-color-extended-yellow-10: #231B00 !default;
$cds-color-extended-yellow-20: #3C2F00 !default;
$cds-color-extended-yellow-30: #564500 !default;
$cds-color-extended-yellow-40: #725C03 !default;
$cds-color-extended-yellow-50: #8D7520 !default;
$cds-color-extended-yellow-60: #A88E38 !default;
$cds-color-extended-yellow-70: #C5A94F !default;
$cds-color-extended-yellow-80: #E2C468 !default;
$cds-color-extended-yellow-90: #FFE081 !default;
$cds-color-extended-yellow-95: #FFF0C9 !default;
$cds-color-extended-yellow-99: #FFFBFF !default;
$cds-color-extended-yellow-100: #FFFFFF !default;
$cds-color-extended-grey-0: #000000 !default;
$cds-color-extended-grey-10: #1B1B1B !default;
$cds-color-extended-grey-20: #303030 !default;
$cds-color-extended-grey-30: #474747 !default;
$cds-color-extended-grey-40: #5E5E5E !default;
$cds-color-extended-grey-50: #777777 !default;
$cds-color-extended-grey-60: #919191 !default;
$cds-color-extended-grey-70: #ABABAB !default;
$cds-color-extended-grey-80: #C6C6C6 !default;
$cds-color-extended-grey-90: #E2E2E2 !default;
$cds-color-extended-grey-95: #F1F1F1 !default;
$cds-color-extended-grey-99: #FCFCFC !default;
$cds-color-extended-grey-100: #FFFFFF !default;
$cds-color-extended-white: #FFFFFF !default;
$cds-color-extended-black: #000000 !default;
$cds-color-interaction-neutral-resting: #002C5200 !default;
$cds-color-interaction-neutral-hovered: #002C520F !default;
$cds-color-interaction-neutral-disabled: #002C520F !default;
$cds-color-interaction-neutral-selected: #002C521A !default;
$cds-color-interaction-neutral-activated: #002C521F !default;
$cds-color-interaction-neutral-dragged: #002C5224 !default;
$cds-color-interaction-neutral-focused: #002C5229 !default;
$cds-color-interaction-neutral-pressed: #002C5233 !default;
$cds-color-interaction-primary-resting: #0046FE00 !default;
$cds-color-interaction-primary-hovered: #0046FE0F !default;
$cds-color-interaction-primary-disabled: #0046FE0F !default;
$cds-color-interaction-primary-selected: #0046FE1A !default;
$cds-color-interaction-primary-activated: #0046FE1F !default;
$cds-color-interaction-primary-dragged: #0046FE24 !default;
$cds-color-interaction-primary-focused: #0046FE29 !default;
$cds-color-interaction-primary-pressed: #0046FE33 !default;
$cds-color-interaction-secondary-resting: #CC492A00 !default;
$cds-color-interaction-secondary-hovered: #CC492A0F !default;
$cds-color-interaction-secondary-disabled: #CC492A0F !default;
$cds-color-interaction-secondary-selected: #CC492A1A !default;
$cds-color-interaction-secondary-activated: #CC492A1F !default;
$cds-color-interaction-secondary-dragged: #CC492A24 !default;
$cds-color-interaction-secondary-focused: #CC492A29 !default;
$cds-color-interaction-secondary-pressed: #CC492A33 !default;
$cds-color-interaction-info-resting: #598EF800 !default;
$cds-color-interaction-info-hovered: #598EF80F !default;
$cds-color-interaction-info-disabled: #598EF80F !default;
$cds-color-interaction-info-selected: #598EF81A !default;
$cds-color-interaction-info-activated: #598EF81F !default;
$cds-color-interaction-info-dragged: #598EF824 !default;
$cds-color-interaction-info-focused: #598EF829 !default;
$cds-color-interaction-info-pressed: #598EF833 !default;
$cds-color-interaction-critical-resting: #FF525D00 !default;
$cds-color-interaction-critical-hovered: #FF525D0F !default;
$cds-color-interaction-critical-disabled: #FF525D0F !default;
$cds-color-interaction-critical-selected: #FF525D1A !default;
$cds-color-interaction-critical-activated: #FF525D1F !default;
$cds-color-interaction-critical-dragged: #FF525D24 !default;
$cds-color-interaction-critical-focused: #FF525D29 !default;
$cds-color-interaction-critical-pressed: #FF525D33 !default;
$cds-color-interaction-warn-resting: #D27C0000 !default;
$cds-color-interaction-warn-hovered: #D27C000F !default;
$cds-color-interaction-warn-disabled: #D27C000F !default;
$cds-color-interaction-warn-selected: #D27C001A !default;
$cds-color-interaction-warn-activated: #D27C001F !default;
$cds-color-interaction-warn-dragged: #D27C0024 !default;
$cds-color-interaction-warn-focused: #D27C0029 !default;
$cds-color-interaction-warn-pressed: #D27C0033 !default;
$cds-color-interaction-success-resting: #689F4D00 !default;
$cds-color-interaction-success-hovered: #689F4D0F !default;
$cds-color-interaction-success-disabled: #689F4D0F !default;
$cds-color-interaction-success-selected: #689F4D1A !default;
$cds-color-interaction-success-activated: #689F4D1F !default;
$cds-color-interaction-success-dragged: #689F4D24 !default;
$cds-color-interaction-success-focused: #689F4D29 !default;
$cds-color-interaction-success-pressed: #689F4D33 !default;
$cds-color-interaction-white-resting: #FFFFFF00 !default;
$cds-color-interaction-white-hovered: #FFFFFF0F !default;
$cds-color-interaction-white-disabled: #FFFFFF0F !default;
$cds-color-interaction-white-selected: #FFFFFF1A !default;
$cds-color-interaction-white-activated: #FFFFFF1F !default;
$cds-color-interaction-white-dragged: #FFFFFF24 !default;
$cds-color-interaction-white-focused: #FFFFFF29 !default;
$cds-color-interaction-white-pressed: #FFFFFF33 !default;
$cds-color-specific-backdrop-neutral: #002C5280 !default;
$cds-color-specific-skeleton-neutral: #002C521F !default;
$cds-color-specific-skeleton-white: #FFFFFF1F !default;
$cds-color-specific-divider-neutral: #002C5233 !default;
$cds-color-text-neutral-high-emphasis: #002C52 !default;
$cds-color-text-neutral-medium-emphasis: #002C52A3 !default;
$cds-color-text-neutral-disabled: #002C5252 !default;
$cds-color-text-primary-high-emphasis: #0046FE !default;
$cds-color-text-primary-disabled: #0046FE52 !default;
$cds-color-text-secondary-high-emphasis: #CC492A !default;
$cds-color-text-secondary-disabled: #CC492A52 !default;
$cds-color-text-info-high-emphasis: #155AC1 !default;
$cds-color-text-info-disabled: #155AC152 !default;
$cds-color-text-critical-high-emphasis: #BC0E2E !default;
$cds-color-text-critical-disabled: #BC0E2E52 !default;
$cds-color-text-warn-high-emphasis: #8B5000 !default;
$cds-color-text-warn-disabled: #8B500052 !default;
$cds-color-text-success-high-emphasis: #376A1F !default;
$cds-color-text-success-disabled: #376A1F52 !default;
$cds-color-text-white-high-emphasis: #FFFFFF !default;
$cds-color-text-white-medium-emphasis: #FFFFFFA3 !default;
$cds-color-text-white-disabled: #FFFFFF52 !default;
$cds-color-text-link-neutral-resting: #002C52A3 !default;
$cds-color-text-link-neutral-hover: #002C52 !default;
$cds-color-text-link-neutral-active: #002C52 !default;
$cds-color-text-link-neutral-focused: #002C52 !default;
$cds-color-text-link-neutral-visited: #002C52A3 !default;
$cds-color-text-link-neutral-disabled: #002C5252 !default;
$cds-color-text-link-primary-resting: #0046FE !default;
$cds-color-text-link-primary-hover: #002EAA !default;
$cds-color-text-link-primary-active: #002EAA !default;
$cds-color-text-link-primary-focused: #002EAA !default;
$cds-color-text-link-primary-visited: #0046FE !default;
$cds-color-text-link-primary-disabled: #0046FE52 !default;
$cds-effect-elevation-umbra-1-offset-x: 0px !default;
$cds-effect-elevation-umbra-1-offset-y: 1px !default;
$cds-effect-elevation-umbra-1-blur-radius: 1px !default;
$cds-effect-elevation-umbra-1-spread-radius: 0px !default;
$cds-effect-elevation-umbra-2-offset-x: 0px !default;
$cds-effect-elevation-umbra-2-offset-y: 2px !default;
$cds-effect-elevation-umbra-2-blur-radius: 1px !default;
$cds-effect-elevation-umbra-2-spread-radius: -1px !default;
$cds-effect-elevation-umbra-4-offset-x: 0px !default;
$cds-effect-elevation-umbra-4-offset-y: 4px !default;
$cds-effect-elevation-umbra-4-blur-radius: 5px !default;
$cds-effect-elevation-umbra-4-spread-radius: 0px !default;
$cds-effect-elevation-umbra-6-offset-x: 0px !default;
$cds-effect-elevation-umbra-6-offset-y: 6px !default;
$cds-effect-elevation-umbra-6-blur-radius: 10px !default;
$cds-effect-elevation-umbra-6-spread-radius: 0px !default;
$cds-effect-elevation-umbra-8-offset-x: 0px !default;
$cds-effect-elevation-umbra-8-offset-y: 8px !default;
$cds-effect-elevation-umbra-8-blur-radius: 10px !default;
$cds-effect-elevation-umbra-8-spread-radius: 1px !default;
$cds-effect-elevation-umbra-12-offset-x: 0px !default;
$cds-effect-elevation-umbra-12-offset-y: 12px !default;
$cds-effect-elevation-umbra-12-blur-radius: 17px !default;
$cds-effect-elevation-umbra-12-spread-radius: 2px !default;
$cds-effect-elevation-umbra-24-offset-x: 0px !default;
$cds-effect-elevation-umbra-24-offset-y: 24px !default;
$cds-effect-elevation-umbra-24-blur-radius: 38px !default;
$cds-effect-elevation-umbra-24-spread-radius: 3px !default;
$cds-effect-elevation-umbra-color: #001D3614 !default;
$cds-effect-elevation-penumbra-1-offset-x: 0px !default;
$cds-effect-elevation-penumbra-1-offset-y: 2px !default;
$cds-effect-elevation-penumbra-1-blur-radius: 1px !default;
$cds-effect-elevation-penumbra-1-spread-radius: -1px !default;
$cds-effect-elevation-penumbra-2-offset-x: 0px !default;
$cds-effect-elevation-penumbra-2-offset-y: 3px !default;
$cds-effect-elevation-penumbra-2-blur-radius: 1px !default;
$cds-effect-elevation-penumbra-2-spread-radius: -2px !default;
$cds-effect-elevation-penumbra-4-offset-x: 0px !default;
$cds-effect-elevation-penumbra-4-offset-y: 1px !default;
$cds-effect-elevation-penumbra-4-blur-radius: 10px !default;
$cds-effect-elevation-penumbra-4-spread-radius: 0px !default;
$cds-effect-elevation-penumbra-6-offset-x: 0px !default;
$cds-effect-elevation-penumbra-6-offset-y: 1px !default;
$cds-effect-elevation-penumbra-6-blur-radius: 18px !default;
$cds-effect-elevation-penumbra-6-spread-radius: 0px !default;
$cds-effect-elevation-penumbra-8-offset-x: 0px !default;
$cds-effect-elevation-penumbra-8-offset-y: 3px !default;
$cds-effect-elevation-penumbra-8-blur-radius: 14px !default;
$cds-effect-elevation-penumbra-8-spread-radius: 2px !default;
$cds-effect-elevation-penumbra-12-offset-x: 0px !default;
$cds-effect-elevation-penumbra-12-offset-y: 5px !default;
$cds-effect-elevation-penumbra-12-blur-radius: 22px !default;
$cds-effect-elevation-penumbra-12-spread-radius: 4px !default;
$cds-effect-elevation-penumbra-24-offset-x: 0px !default;
$cds-effect-elevation-penumbra-24-offset-y: 9px !default;
$cds-effect-elevation-penumbra-24-blur-radius: 46px !default;
$cds-effect-elevation-penumbra-24-spread-radius: -8px !default;
$cds-effect-elevation-penumbra-color: #001D361F !default;
$cds-effect-elevation-ambient-1-offset-x: 0px !default;
$cds-effect-elevation-ambient-1-offset-y: 1px !default;
$cds-effect-elevation-ambient-1-blur-radius: 3px !default;
$cds-effect-elevation-ambient-1-spread-radius: 0px !default;
$cds-effect-elevation-ambient-2-offset-x: 0px !default;
$cds-effect-elevation-ambient-2-offset-y: 1px !default;
$cds-effect-elevation-ambient-2-blur-radius: 5px !default;
$cds-effect-elevation-ambient-2-spread-radius: 0px !default;
$cds-effect-elevation-ambient-4-offset-x: 0px !default;
$cds-effect-elevation-ambient-4-offset-y: 2px !default;
$cds-effect-elevation-ambient-4-blur-radius: 4px !default;
$cds-effect-elevation-ambient-4-spread-radius: -1px !default;
$cds-effect-elevation-ambient-6-offset-x: 0px !default;
$cds-effect-elevation-ambient-6-offset-y: 3px !default;
$cds-effect-elevation-ambient-6-blur-radius: 5px !default;
$cds-effect-elevation-ambient-6-spread-radius: -1px !default;
$cds-effect-elevation-ambient-8-offset-x: 0px !default;
$cds-effect-elevation-ambient-8-offset-y: 5px !default;
$cds-effect-elevation-ambient-8-blur-radius: 5px !default;
$cds-effect-elevation-ambient-8-spread-radius: -3px !default;
$cds-effect-elevation-ambient-12-offset-x: 0px !default;
$cds-effect-elevation-ambient-12-offset-y: 7px !default;
$cds-effect-elevation-ambient-12-blur-radius: 8px !default;
$cds-effect-elevation-ambient-12-spread-radius: -4px !default;
$cds-effect-elevation-ambient-24-offset-x: 0px !default;
$cds-effect-elevation-ambient-24-offset-y: 11px !default;
$cds-effect-elevation-ambient-24-blur-radius: 15px !default;
$cds-effect-elevation-ambient-24-spread-radius: -15px !default;
$cds-effect-elevation-ambient-color: #001D361F !default;
$cds-size-breakpoint-xs: 0px !default;
$cds-size-breakpoint-sm: 600px !default;
$cds-size-breakpoint-md: 960px !default;
$cds-size-breakpoint-lg: 1280px !default;
$cds-size-breakpoint-xl: 1920px !default;
$cds-size-spacing: 8px !default;
$cds-size-radius: 6px !default;
$cds-size-divider: 1px !default;
$cds-size-border: 1px !default;
$cds-size-border-bold: 2px !default;
$cds-size-specific: 40px !default;
$cds-size-specific-xxs: 16px !default;
$cds-size-specific-xs: 20px !default;
$cds-size-specific-s: 24px !default;
$cds-size-specific-m: 32px !default;
$cds-size-specific-l: 56px !default;
$cds-size-specific-xl: 72px !default;
$cds-size-specific-xxl: 100px !default;
$cds-opacity-decorative: 20 !default;
$cds-opacity-disabled: 32 !default;
$cds-opacity-hexa-0: 00 !default;
$cds-opacity-hexa-1: 03 !default;
$cds-opacity-hexa-2: 05 !default;
$cds-opacity-hexa-3: 08 !default;
$cds-opacity-hexa-4: 0A !default;
$cds-opacity-hexa-5: 0D !default;
$cds-opacity-hexa-6: 0F !default;
$cds-opacity-hexa-7: 12 !default;
$cds-opacity-hexa-8: 14 !default;
$cds-opacity-hexa-9: 17 !default;
$cds-opacity-hexa-10: 1A !default;
$cds-opacity-hexa-11: 1C !default;
$cds-opacity-hexa-12: 1F !default;
$cds-opacity-hexa-13: 21 !default;
$cds-opacity-hexa-14: 24 !default;
$cds-opacity-hexa-15: 26 !default;
$cds-opacity-hexa-16: 29 !default;
$cds-opacity-hexa-17: 2B !default;
$cds-opacity-hexa-18: 2E !default;
$cds-opacity-hexa-19: 30 !default;
$cds-opacity-hexa-20: 33 !default;
$cds-opacity-hexa-21: 36 !default;
$cds-opacity-hexa-22: 38 !default;
$cds-opacity-hexa-23: 3B !default;
$cds-opacity-hexa-24: 3D !default;
$cds-opacity-hexa-25: 40 !default;
$cds-opacity-hexa-26: 42 !default;
$cds-opacity-hexa-27: 45 !default;
$cds-opacity-hexa-28: 47 !default;
$cds-opacity-hexa-29: 4A !default;
$cds-opacity-hexa-30: 4D !default;
$cds-opacity-hexa-31: 4F !default;
$cds-opacity-hexa-32: 52 !default;
$cds-opacity-hexa-33: 54 !default;
$cds-opacity-hexa-34: 57 !default;
$cds-opacity-hexa-35: 59 !default;
$cds-opacity-hexa-36: 5C !default;
$cds-opacity-hexa-37: 5E !default;
$cds-opacity-hexa-38: 61 !default;
$cds-opacity-hexa-39: 63 !default;
$cds-opacity-hexa-40: 66 !default;
$cds-opacity-hexa-41: 69 !default;
$cds-opacity-hexa-42: 6B !default;
$cds-opacity-hexa-43: 6E !default;
$cds-opacity-hexa-44: 70 !default;
$cds-opacity-hexa-45: 73 !default;
$cds-opacity-hexa-46: 75 !default;
$cds-opacity-hexa-47: 78 !default;
$cds-opacity-hexa-48: 7A !default;
$cds-opacity-hexa-49: 7D !default;
$cds-opacity-hexa-50: 7F !default;
$cds-opacity-hexa-51: 82 !default;
$cds-opacity-hexa-52: 85 !default;
$cds-opacity-hexa-53: 87 !default;
$cds-opacity-hexa-54: 8A !default;
$cds-opacity-hexa-55: 8C !default;
$cds-opacity-hexa-56: 8F !default;
$cds-opacity-hexa-57: 91 !default;
$cds-opacity-hexa-58: 94 !default;
$cds-opacity-hexa-59: 96 !default;
$cds-opacity-hexa-60: 99 !default;
$cds-opacity-hexa-61: 9C !default;
$cds-opacity-hexa-62: 9E !default;
$cds-opacity-hexa-63: A1 !default;
$cds-opacity-hexa-64: A3 !default;
$cds-opacity-hexa-65: A6 !default;
$cds-opacity-hexa-66: A8 !default;
$cds-opacity-hexa-67: AB !default;
$cds-opacity-hexa-68: AD !default;
$cds-opacity-hexa-69: B0 !default;
$cds-opacity-hexa-70: B3 !default;
$cds-opacity-hexa-71: B5 !default;
$cds-opacity-hexa-72: B8 !default;
$cds-opacity-hexa-73: BA !default;
$cds-opacity-hexa-74: BD !default;
$cds-opacity-hexa-75: BF !default;
$cds-opacity-hexa-76: C2 !default;
$cds-opacity-hexa-77: C4 !default;
$cds-opacity-hexa-78: C7 !default;
$cds-opacity-hexa-79: C9 !default;
$cds-opacity-hexa-80: CC !default;
$cds-opacity-hexa-81: CF !default;
$cds-opacity-hexa-82: D1 !default;
$cds-opacity-hexa-83: D4 !default;
$cds-opacity-hexa-84: D6 !default;
$cds-opacity-hexa-85: D9 !default;
$cds-opacity-hexa-86: DB !default;
$cds-opacity-hexa-87: DE !default;
$cds-opacity-hexa-88: E0 !default;
$cds-opacity-hexa-89: E3 !default;
$cds-opacity-hexa-90: E5 !default;
$cds-opacity-hexa-91: E8 !default;
$cds-opacity-hexa-92: EB !default;
$cds-opacity-hexa-93: ED !default;
$cds-opacity-hexa-94: F0 !default;
$cds-opacity-hexa-95: F2 !default;
$cds-opacity-hexa-96: F5 !default;
$cds-opacity-hexa-97: F7 !default;
$cds-opacity-hexa-98: FA !default;
$cds-opacity-hexa-99: FC !default;
$cds-opacity-hexa-100: FF !default;
$cds-opacity-inactive: 64 !default;
$cds-typography-headline-1-family: Poppins, 'Helvetica', 'Arial', sans-serif !default;
$cds-typography-headline-1-font-size: 56px !default;
$cds-typography-headline-1-font-weight: 300 !default;
$cds-typography-headline-1-line-height: 80px !default;
$cds-typography-headline-1-letter-spacing: -2px !default;
$cds-typography-headline-2-family: Poppins, 'Helvetica', 'Arial', sans-serif !default;
$cds-typography-headline-2-font-size: 48px !default;
$cds-typography-headline-2-font-weight: 300 !default;
$cds-typography-headline-2-line-height: 64px !default;
$cds-typography-headline-2-letter-spacing: -1px !default;
$cds-typography-headline-3-family: Poppins, 'Helvetica', 'Arial', sans-serif !default;
$cds-typography-headline-3-font-size: 38px !default;
$cds-typography-headline-3-font-weight: 400 !default;
$cds-typography-headline-3-line-height: 54px !default;
$cds-typography-headline-3-letter-spacing: -0.75px !default;
$cds-typography-headline-4-family: Poppins, 'Helvetica', 'Arial', sans-serif !default;
$cds-typography-headline-4-font-size: 32px !default;
$cds-typography-headline-4-font-weight: 400 !default;
$cds-typography-headline-4-line-height: 44px !default;
$cds-typography-headline-4-letter-spacing: -0.75px !default;
$cds-typography-headline-5-family: Poppins, 'Helvetica', 'Arial', sans-serif !default;
$cds-typography-headline-5-font-size: 24px !default;
$cds-typography-headline-5-font-weight: 400 !default;
$cds-typography-headline-5-line-height: 34px !default;
$cds-typography-headline-5-letter-spacing: -0.25px !default;
$cds-typography-headline-6-family: Poppins, 'Helvetica', 'Arial', sans-serif !default;
$cds-typography-headline-6-font-size: 20px !default;
$cds-typography-headline-6-font-weight: 600 !default;
$cds-typography-headline-6-line-height: 28px !default;
$cds-typography-headline-6-letter-spacing: -0.25px !default;
$cds-typography-subtitle-1-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !default;
$cds-typography-subtitle-1-font-size: 16px !default;
$cds-typography-subtitle-1-font-weight: 400 !default;
$cds-typography-subtitle-1-line-height: 21px !default;
$cds-typography-subtitle-1-letter-spacing: 0.15px !default;
$cds-typography-subtitle-2-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !default;
$cds-typography-subtitle-2-font-size: 14px !default;
$cds-typography-subtitle-2-font-weight: 500 !default;
$cds-typography-subtitle-2-line-height: 22px !default;
$cds-typography-subtitle-2-letter-spacing: 0.1px !default;
$cds-typography-body-1-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !default;
$cds-typography-body-1-font-size: 16px !default;
$cds-typography-body-1-font-weight: 400 !default;
$cds-typography-body-1-line-height: 28px !default;
$cds-typography-body-1-letter-spacing: 0.5px !default;
$cds-typography-body-2-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !default;
$cds-typography-body-2-font-size: 14px !default;
$cds-typography-body-2-font-weight: 400 !default;
$cds-typography-body-2-line-height: 20px !default;
$cds-typography-body-2-letter-spacing: 0.25px !default;
$cds-typography-label-family: Poppins, 'Helvetica', 'Arial', sans-serif !default;
$cds-typography-label-font-size: 14px !default;
$cds-typography-label-font-weight: 500 !default;
$cds-typography-label-line-height: 20px !default;
$cds-typography-label-letter-spacing: 0.5px !default;
$cds-typography-caption-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !default;
$cds-typography-caption-font-size: 12px !default;
$cds-typography-caption-font-weight: 400 !default;
$cds-typography-caption-line-height: 16px !default;
$cds-typography-caption-letter-spacing: 0.4px !default;
$cds-typography-overline-family: Poppins, 'Helvetica', 'Arial', sans-serif !default;
$cds-typography-overline-font-size: 11px !default;
$cds-typography-overline-font-weight: 600 !default;
$cds-typography-overline-line-height: 16px !default;
$cds-typography-overline-letter-spacing: 1.83px !default;
$cds-typography-button-family: Poppins, 'Helvetica', 'Arial', sans-serif !default;
$cds-typography-button-font-size: 14px !default;
$cds-typography-button-font-weight: 600 !default;
$cds-typography-button-line-height: 20px !default;
$cds-typography-button-letter-spacing: 0.5px !default;

$tokens: (
  'color': (
    'action': (
      'neutral': (
        'active': $cds-color-action-neutral-active,
        'inactive': $cds-color-action-neutral-inactive,
        'disabled': $cds-color-action-neutral-disabled
      ),
      'primary': (
        'active': $cds-color-action-primary-active,
        'disabled': $cds-color-action-primary-disabled
      ),
      'secondary': (
        'active': $cds-color-action-secondary-active,
        'disabled': $cds-color-action-secondary-disabled
      ),
      'white': (
        'active': $cds-color-action-white-active,
        'inactive': $cds-color-action-white-inactive,
        'disabled': $cds-color-action-white-disabled
      ),
      'info': (
        'dark': (
          'active': $cds-color-action-info-dark-active,
          'disabled': $cds-color-action-info-dark-disabled
        )
      ),
      'critical': (
        'main': (
          'active': $cds-color-action-critical-main-active,
          'disabled': $cds-color-action-critical-main-disabled
        ),
        'dark': (
          'active': $cds-color-action-critical-dark-active,
          'disabled': $cds-color-action-critical-dark-disabled
        )
      ),
      'warn': (
        'dark': (
          'active': $cds-color-action-warn-dark-active,
          'disabled': $cds-color-action-warn-dark-disabled
        )
      ),
      'success': (
        'dark': (
          'active': $cds-color-action-success-dark-active,
          'disabled': $cds-color-action-success-dark-disabled
        )
      )
    ),
    'background': (
      'neutral': (
        'main': (
          '_': $cds-color-background-neutral-main,
          'disabled': $cds-color-background-neutral-main-disabled
        ),
        'light': $cds-color-background-neutral-light
      ),
      'primary': (
        'main': (
          '_': $cds-color-background-primary-main,
          'disabled': $cds-color-background-primary-main-disabled
        ),
        'light': $cds-color-background-primary-light
      ),
      'secondary': (
        'main': (
          '_': $cds-color-background-secondary-main,
          'disabled': $cds-color-background-secondary-main-disabled
        ),
        'light': $cds-color-background-secondary-light
      ),
      'info': (
        'main': (
          '_': $cds-color-background-info-main,
          'disabled': $cds-color-background-info-main-disabled
        ),
        'light': $cds-color-background-info-light,
        'dark': (
          '_': $cds-color-background-info-dark,
          'disabled': $cds-color-background-info-dark-disabled
        )
      ),
      'critical': (
        'main': (
          '_': $cds-color-background-critical-main,
          'disabled': $cds-color-background-critical-main-disabled
        ),
        'light': $cds-color-background-critical-light,
        'dark': (
          '_': $cds-color-background-critical-dark,
          'disabled': $cds-color-background-critical-dark-disabled
        )
      ),
      'warn': (
        'main': (
          '_': $cds-color-background-warn-main,
          'disabled': $cds-color-background-warn-main-disabled
        ),
        'light': $cds-color-background-warn-light,
        'dark': (
          '_': $cds-color-background-warn-dark,
          'disabled': $cds-color-background-warn-dark-disabled
        )
      ),
      'success': (
        'main': (
          '_': $cds-color-background-success-main,
          'disabled': $cds-color-background-success-main-disabled
        ),
        'light': $cds-color-background-success-light,
        'dark': (
          '_': $cds-color-background-success-dark,
          'disabled': $cds-color-background-success-dark-disabled
        )
      ),
      'white': (
        'main': (
          '_': $cds-color-background-white-main,
          'disabled': $cds-color-background-white-main-disabled
        )
      )
    ),
    'border': (
      'neutral': (
        'highEmphasis': $cds-color-border-neutral-high-emphasis,
        'mediumEmphasis': $cds-color-border-neutral-medium-emphasis,
        'disabled': $cds-color-border-neutral-disabled,
        'decorative': $cds-color-border-neutral-decorative
      ),
      'primary': (
        'highEmphasis': $cds-color-border-primary-high-emphasis,
        'disabled': $cds-color-border-primary-disabled
      ),
      'white': (
        'highEmphasis': $cds-color-border-white-high-emphasis,
        'mediumEmphasis': $cds-color-border-white-medium-emphasis,
        'disabled': $cds-color-border-white-disabled
      ),
      'critical': (
        'main': (
          'highEmphasis': $cds-color-border-critical-main-high-emphasis,
          'disabled': $cds-color-border-critical-main-disabled
        ),
        'dark': (
          'highEmphasis': $cds-color-border-critical-dark-high-emphasis,
          'disabled': $cds-color-border-critical-dark-disabled
        )
      )
    ),
    'brand': (
      'primary': (
        '0': $cds-color-brand-primary-0,
        '10': $cds-color-brand-primary-10,
        '20': $cds-color-brand-primary-20,
        '30': $cds-color-brand-primary-30,
        '40': $cds-color-brand-primary-40,
        '50': $cds-color-brand-primary-50,
        '60': $cds-color-brand-primary-60,
        '70': $cds-color-brand-primary-70,
        '80': $cds-color-brand-primary-80,
        '90': $cds-color-brand-primary-90,
        '95': $cds-color-brand-primary-95,
        '99': $cds-color-brand-primary-99,
        '100': $cds-color-brand-primary-100
      ),
      'secondary': (
        '0': $cds-color-brand-secondary-0,
        '10': $cds-color-brand-secondary-10,
        '20': $cds-color-brand-secondary-20,
        '30': $cds-color-brand-secondary-30,
        '40': $cds-color-brand-secondary-40,
        '50': $cds-color-brand-secondary-50,
        '60': $cds-color-brand-secondary-60,
        '70': $cds-color-brand-secondary-70,
        '80': $cds-color-brand-secondary-80,
        '90': $cds-color-brand-secondary-90,
        '95': $cds-color-brand-secondary-95,
        '99': $cds-color-brand-secondary-99,
        '100': $cds-color-brand-secondary-100
      ),
      'neutral': (
        '0': $cds-color-brand-neutral-0,
        '10': $cds-color-brand-neutral-10,
        '20': $cds-color-brand-neutral-20,
        '30': $cds-color-brand-neutral-30,
        '40': $cds-color-brand-neutral-40,
        '50': $cds-color-brand-neutral-50,
        '60': $cds-color-brand-neutral-60,
        '70': $cds-color-brand-neutral-70,
        '80': $cds-color-brand-neutral-80,
        '90': $cds-color-brand-neutral-90,
        '95': $cds-color-brand-neutral-95,
        '99': $cds-color-brand-neutral-99,
        '100': $cds-color-brand-neutral-100
      ),
      'bananabread': (
        '0': $cds-color-brand-bananabread-0,
        '10': $cds-color-brand-bananabread-10,
        '20': $cds-color-brand-bananabread-20,
        '30': $cds-color-brand-bananabread-30,
        '40': $cds-color-brand-bananabread-40,
        '50': $cds-color-brand-bananabread-50,
        '60': $cds-color-brand-bananabread-60,
        '70': $cds-color-brand-bananabread-70,
        '80': $cds-color-brand-bananabread-80,
        '90': $cds-color-brand-bananabread-90,
        '95': $cds-color-brand-bananabread-95,
        '99': $cds-color-brand-bananabread-99,
        '100': $cds-color-brand-bananabread-100
      ),
      'blackberry': (
        '0': $cds-color-brand-blackberry-0,
        '10': $cds-color-brand-blackberry-10,
        '20': $cds-color-brand-blackberry-20,
        '30': $cds-color-brand-blackberry-30,
        '40': $cds-color-brand-blackberry-40,
        '50': $cds-color-brand-blackberry-50,
        '60': $cds-color-brand-blackberry-60,
        '70': $cds-color-brand-blackberry-70,
        '80': $cds-color-brand-blackberry-80,
        '90': $cds-color-brand-blackberry-90,
        '95': $cds-color-brand-blackberry-95,
        '99': $cds-color-brand-blackberry-99,
        '100': $cds-color-brand-blackberry-100
      ),
      'blueberry': (
        '0': $cds-color-brand-blueberry-0,
        '10': $cds-color-brand-blueberry-10,
        '20': $cds-color-brand-blueberry-20,
        '30': $cds-color-brand-blueberry-30,
        '40': $cds-color-brand-blueberry-40,
        '50': $cds-color-brand-blueberry-50,
        '60': $cds-color-brand-blueberry-60,
        '70': $cds-color-brand-blueberry-70,
        '80': $cds-color-brand-blueberry-80,
        '90': $cds-color-brand-blueberry-90,
        '95': $cds-color-brand-blueberry-95,
        '99': $cds-color-brand-blueberry-99,
        '100': $cds-color-brand-blueberry-100
      ),
      'gingerbread': (
        '0': $cds-color-brand-gingerbread-0,
        '10': $cds-color-brand-gingerbread-10,
        '20': $cds-color-brand-gingerbread-20,
        '30': $cds-color-brand-gingerbread-30,
        '40': $cds-color-brand-gingerbread-40,
        '50': $cds-color-brand-gingerbread-50,
        '60': $cds-color-brand-gingerbread-60,
        '70': $cds-color-brand-gingerbread-70,
        '80': $cds-color-brand-gingerbread-80,
        '90': $cds-color-brand-gingerbread-90,
        '95': $cds-color-brand-gingerbread-95,
        '99': $cds-color-brand-gingerbread-99,
        '100': $cds-color-brand-gingerbread-100
      ),
      'lychee': (
        '0': $cds-color-brand-lychee-0,
        '10': $cds-color-brand-lychee-10,
        '20': $cds-color-brand-lychee-20,
        '30': $cds-color-brand-lychee-30,
        '40': $cds-color-brand-lychee-40,
        '50': $cds-color-brand-lychee-50,
        '60': $cds-color-brand-lychee-60,
        '70': $cds-color-brand-lychee-70,
        '80': $cds-color-brand-lychee-80,
        '90': $cds-color-brand-lychee-90,
        '95': $cds-color-brand-lychee-95,
        '99': $cds-color-brand-lychee-99,
        '100': $cds-color-brand-lychee-100
      ),
      'plum': (
        '0': $cds-color-brand-plum-0,
        '10': $cds-color-brand-plum-10,
        '20': $cds-color-brand-plum-20,
        '30': $cds-color-brand-plum-30,
        '40': $cds-color-brand-plum-40,
        '50': $cds-color-brand-plum-50,
        '60': $cds-color-brand-plum-60,
        '70': $cds-color-brand-plum-70,
        '80': $cds-color-brand-plum-80,
        '90': $cds-color-brand-plum-90,
        '95': $cds-color-brand-plum-95,
        '99': $cds-color-brand-plum-99,
        '100': $cds-color-brand-plum-100
      ),
      'tomato': (
        '0': $cds-color-brand-tomato-0,
        '10': $cds-color-brand-tomato-10,
        '20': $cds-color-brand-tomato-20,
        '30': $cds-color-brand-tomato-30,
        '40': $cds-color-brand-tomato-40,
        '50': $cds-color-brand-tomato-50,
        '60': $cds-color-brand-tomato-60,
        '70': $cds-color-brand-tomato-70,
        '80': $cds-color-brand-tomato-80,
        '90': $cds-color-brand-tomato-90,
        '95': $cds-color-brand-tomato-95,
        '99': $cds-color-brand-tomato-99,
        '100': $cds-color-brand-tomato-100
      )
    ),
    'datavViz': (
      '10': $cds-color-datav-viz-10,
      '11': $cds-color-datav-viz-11,
      '12': $cds-color-datav-viz-12,
      '13': $cds-color-datav-viz-13,
      '14': $cds-color-datav-viz-14,
      '15': $cds-color-datav-viz-15,
      '16': $cds-color-datav-viz-16,
      '01': $cds-color-datav-viz-01,
      '02': $cds-color-datav-viz-02,
      '03': $cds-color-datav-viz-03,
      '04': $cds-color-datav-viz-04,
      '05': $cds-color-datav-viz-05,
      '06': $cds-color-datav-viz-06,
      '07': $cds-color-datav-viz-07,
      '08': $cds-color-datav-viz-08,
      '09': $cds-color-datav-viz-09
    ),
    'extended': (
      'blue': (
        '0': $cds-color-extended-blue-0,
        '10': $cds-color-extended-blue-10,
        '20': $cds-color-extended-blue-20,
        '30': $cds-color-extended-blue-30,
        '40': $cds-color-extended-blue-40,
        '50': $cds-color-extended-blue-50,
        '60': $cds-color-extended-blue-60,
        '70': $cds-color-extended-blue-70,
        '80': $cds-color-extended-blue-80,
        '90': $cds-color-extended-blue-90,
        '95': $cds-color-extended-blue-95,
        '99': $cds-color-extended-blue-99,
        '100': $cds-color-extended-blue-100
      ),
      'blue_grey': (
        '0': $cds-color-extended-blue-grey-0,
        '10': $cds-color-extended-blue-grey-10,
        '20': $cds-color-extended-blue-grey-20,
        '30': $cds-color-extended-blue-grey-30,
        '40': $cds-color-extended-blue-grey-40,
        '50': $cds-color-extended-blue-grey-50,
        '60': $cds-color-extended-blue-grey-60,
        '70': $cds-color-extended-blue-grey-70,
        '80': $cds-color-extended-blue-grey-80,
        '90': $cds-color-extended-blue-grey-90,
        '95': $cds-color-extended-blue-grey-95,
        '99': $cds-color-extended-blue-grey-99,
        '100': $cds-color-extended-blue-grey-100
      ),
      'brown': (
        '0': $cds-color-extended-brown-0,
        '10': $cds-color-extended-brown-10,
        '20': $cds-color-extended-brown-20,
        '30': $cds-color-extended-brown-30,
        '40': $cds-color-extended-brown-40,
        '50': $cds-color-extended-brown-50,
        '60': $cds-color-extended-brown-60,
        '70': $cds-color-extended-brown-70,
        '80': $cds-color-extended-brown-80,
        '90': $cds-color-extended-brown-90,
        '95': $cds-color-extended-brown-95,
        '99': $cds-color-extended-brown-99,
        '100': $cds-color-extended-brown-100
      ),
      'cyan': (
        '0': $cds-color-extended-cyan-0,
        '10': $cds-color-extended-cyan-10,
        '20': $cds-color-extended-cyan-20,
        '30': $cds-color-extended-cyan-30,
        '40': $cds-color-extended-cyan-40,
        '50': $cds-color-extended-cyan-50,
        '60': $cds-color-extended-cyan-60,
        '70': $cds-color-extended-cyan-70,
        '80': $cds-color-extended-cyan-80,
        '90': $cds-color-extended-cyan-90,
        '95': $cds-color-extended-cyan-95,
        '99': $cds-color-extended-cyan-99,
        '100': $cds-color-extended-cyan-100
      ),
      'deep_orange': (
        '0': $cds-color-extended-deep-orange-0,
        '10': $cds-color-extended-deep-orange-10,
        '20': $cds-color-extended-deep-orange-20,
        '30': $cds-color-extended-deep-orange-30,
        '40': $cds-color-extended-deep-orange-40,
        '50': $cds-color-extended-deep-orange-50,
        '60': $cds-color-extended-deep-orange-60,
        '70': $cds-color-extended-deep-orange-70,
        '80': $cds-color-extended-deep-orange-80,
        '90': $cds-color-extended-deep-orange-90,
        '95': $cds-color-extended-deep-orange-95,
        '99': $cds-color-extended-deep-orange-99,
        '100': $cds-color-extended-deep-orange-100
      ),
      'deep_purple': (
        '0': $cds-color-extended-deep-purple-0,
        '10': $cds-color-extended-deep-purple-10,
        '20': $cds-color-extended-deep-purple-20,
        '30': $cds-color-extended-deep-purple-30,
        '40': $cds-color-extended-deep-purple-40,
        '50': $cds-color-extended-deep-purple-50,
        '60': $cds-color-extended-deep-purple-60,
        '70': $cds-color-extended-deep-purple-70,
        '80': $cds-color-extended-deep-purple-80,
        '90': $cds-color-extended-deep-purple-90,
        '95': $cds-color-extended-deep-purple-95,
        '99': $cds-color-extended-deep-purple-99,
        '100': $cds-color-extended-deep-purple-100
      ),
      'green': (
        '0': $cds-color-extended-green-0,
        '10': $cds-color-extended-green-10,
        '20': $cds-color-extended-green-20,
        '30': $cds-color-extended-green-30,
        '40': $cds-color-extended-green-40,
        '50': $cds-color-extended-green-50,
        '60': $cds-color-extended-green-60,
        '70': $cds-color-extended-green-70,
        '80': $cds-color-extended-green-80,
        '90': $cds-color-extended-green-90,
        '95': $cds-color-extended-green-95,
        '99': $cds-color-extended-green-99,
        '100': $cds-color-extended-green-100
      ),
      'orange': (
        '0': $cds-color-extended-orange-0,
        '10': $cds-color-extended-orange-10,
        '20': $cds-color-extended-orange-20,
        '30': $cds-color-extended-orange-30,
        '40': $cds-color-extended-orange-40,
        '50': $cds-color-extended-orange-50,
        '60': $cds-color-extended-orange-60,
        '70': $cds-color-extended-orange-70,
        '80': $cds-color-extended-orange-80,
        '90': $cds-color-extended-orange-90,
        '95': $cds-color-extended-orange-95,
        '99': $cds-color-extended-orange-99,
        '100': $cds-color-extended-orange-100
      ),
      'pink': (
        '0': $cds-color-extended-pink-0,
        '10': $cds-color-extended-pink-10,
        '20': $cds-color-extended-pink-20,
        '30': $cds-color-extended-pink-30,
        '40': $cds-color-extended-pink-40,
        '50': $cds-color-extended-pink-50,
        '60': $cds-color-extended-pink-60,
        '70': $cds-color-extended-pink-70,
        '80': $cds-color-extended-pink-80,
        '90': $cds-color-extended-pink-90,
        '95': $cds-color-extended-pink-95,
        '99': $cds-color-extended-pink-99,
        '100': $cds-color-extended-pink-100
      ),
      'strawberry': (
        '0': $cds-color-extended-strawberry-0,
        '10': $cds-color-extended-strawberry-10,
        '20': $cds-color-extended-strawberry-20,
        '30': $cds-color-extended-strawberry-30,
        '40': $cds-color-extended-strawberry-40,
        '50': $cds-color-extended-strawberry-50,
        '60': $cds-color-extended-strawberry-60,
        '70': $cds-color-extended-strawberry-70,
        '80': $cds-color-extended-strawberry-80,
        '90': $cds-color-extended-strawberry-90,
        '95': $cds-color-extended-strawberry-95,
        '99': $cds-color-extended-strawberry-99,
        '100': $cds-color-extended-strawberry-100
      ),
      'yellow': (
        '0': $cds-color-extended-yellow-0,
        '10': $cds-color-extended-yellow-10,
        '20': $cds-color-extended-yellow-20,
        '30': $cds-color-extended-yellow-30,
        '40': $cds-color-extended-yellow-40,
        '50': $cds-color-extended-yellow-50,
        '60': $cds-color-extended-yellow-60,
        '70': $cds-color-extended-yellow-70,
        '80': $cds-color-extended-yellow-80,
        '90': $cds-color-extended-yellow-90,
        '95': $cds-color-extended-yellow-95,
        '99': $cds-color-extended-yellow-99,
        '100': $cds-color-extended-yellow-100
      ),
      'grey': (
        '0': $cds-color-extended-grey-0,
        '10': $cds-color-extended-grey-10,
        '20': $cds-color-extended-grey-20,
        '30': $cds-color-extended-grey-30,
        '40': $cds-color-extended-grey-40,
        '50': $cds-color-extended-grey-50,
        '60': $cds-color-extended-grey-60,
        '70': $cds-color-extended-grey-70,
        '80': $cds-color-extended-grey-80,
        '90': $cds-color-extended-grey-90,
        '95': $cds-color-extended-grey-95,
        '99': $cds-color-extended-grey-99,
        '100': $cds-color-extended-grey-100
      ),
      'white': $cds-color-extended-white,
      'black': $cds-color-extended-black
    ),
    'interaction': (
      'neutral': (
        'resting': $cds-color-interaction-neutral-resting,
        'hovered': $cds-color-interaction-neutral-hovered,
        'disabled': $cds-color-interaction-neutral-disabled,
        'selected': $cds-color-interaction-neutral-selected,
        'activated': $cds-color-interaction-neutral-activated,
        'dragged': $cds-color-interaction-neutral-dragged,
        'focused': $cds-color-interaction-neutral-focused,
        'pressed': $cds-color-interaction-neutral-pressed
      ),
      'primary': (
        'resting': $cds-color-interaction-primary-resting,
        'hovered': $cds-color-interaction-primary-hovered,
        'disabled': $cds-color-interaction-primary-disabled,
        'selected': $cds-color-interaction-primary-selected,
        'activated': $cds-color-interaction-primary-activated,
        'dragged': $cds-color-interaction-primary-dragged,
        'focused': $cds-color-interaction-primary-focused,
        'pressed': $cds-color-interaction-primary-pressed
      ),
      'secondary': (
        'resting': $cds-color-interaction-secondary-resting,
        'hovered': $cds-color-interaction-secondary-hovered,
        'disabled': $cds-color-interaction-secondary-disabled,
        'selected': $cds-color-interaction-secondary-selected,
        'activated': $cds-color-interaction-secondary-activated,
        'dragged': $cds-color-interaction-secondary-dragged,
        'focused': $cds-color-interaction-secondary-focused,
        'pressed': $cds-color-interaction-secondary-pressed
      ),
      'info': (
        'resting': $cds-color-interaction-info-resting,
        'hovered': $cds-color-interaction-info-hovered,
        'disabled': $cds-color-interaction-info-disabled,
        'selected': $cds-color-interaction-info-selected,
        'activated': $cds-color-interaction-info-activated,
        'dragged': $cds-color-interaction-info-dragged,
        'focused': $cds-color-interaction-info-focused,
        'pressed': $cds-color-interaction-info-pressed
      ),
      'critical': (
        'resting': $cds-color-interaction-critical-resting,
        'hovered': $cds-color-interaction-critical-hovered,
        'disabled': $cds-color-interaction-critical-disabled,
        'selected': $cds-color-interaction-critical-selected,
        'activated': $cds-color-interaction-critical-activated,
        'dragged': $cds-color-interaction-critical-dragged,
        'focused': $cds-color-interaction-critical-focused,
        'pressed': $cds-color-interaction-critical-pressed
      ),
      'warn': (
        'resting': $cds-color-interaction-warn-resting,
        'hovered': $cds-color-interaction-warn-hovered,
        'disabled': $cds-color-interaction-warn-disabled,
        'selected': $cds-color-interaction-warn-selected,
        'activated': $cds-color-interaction-warn-activated,
        'dragged': $cds-color-interaction-warn-dragged,
        'focused': $cds-color-interaction-warn-focused,
        'pressed': $cds-color-interaction-warn-pressed
      ),
      'success': (
        'resting': $cds-color-interaction-success-resting,
        'hovered': $cds-color-interaction-success-hovered,
        'disabled': $cds-color-interaction-success-disabled,
        'selected': $cds-color-interaction-success-selected,
        'activated': $cds-color-interaction-success-activated,
        'dragged': $cds-color-interaction-success-dragged,
        'focused': $cds-color-interaction-success-focused,
        'pressed': $cds-color-interaction-success-pressed
      ),
      'white': (
        'resting': $cds-color-interaction-white-resting,
        'hovered': $cds-color-interaction-white-hovered,
        'disabled': $cds-color-interaction-white-disabled,
        'selected': $cds-color-interaction-white-selected,
        'activated': $cds-color-interaction-white-activated,
        'dragged': $cds-color-interaction-white-dragged,
        'focused': $cds-color-interaction-white-focused,
        'pressed': $cds-color-interaction-white-pressed
      )
    ),
    'specific': (
      'backdrop': (
        'neutral': $cds-color-specific-backdrop-neutral
      ),
      'skeleton': (
        'neutral': $cds-color-specific-skeleton-neutral,
        'white': $cds-color-specific-skeleton-white
      ),
      'divider': (
        'neutral': $cds-color-specific-divider-neutral
      )
    ),
    'text': (
      'neutral': (
        'highEmphasis': $cds-color-text-neutral-high-emphasis,
        'mediumEmphasis': $cds-color-text-neutral-medium-emphasis,
        'disabled': $cds-color-text-neutral-disabled
      ),
      'primary': (
        'highEmphasis': $cds-color-text-primary-high-emphasis,
        'disabled': $cds-color-text-primary-disabled
      ),
      'secondary': (
        'highEmphasis': $cds-color-text-secondary-high-emphasis,
        'disabled': $cds-color-text-secondary-disabled
      ),
      'info': (
        'highEmphasis': $cds-color-text-info-high-emphasis,
        'disabled': $cds-color-text-info-disabled
      ),
      'critical': (
        'highEmphasis': $cds-color-text-critical-high-emphasis,
        'disabled': $cds-color-text-critical-disabled
      ),
      'warn': (
        'highEmphasis': $cds-color-text-warn-high-emphasis,
        'disabled': $cds-color-text-warn-disabled
      ),
      'success': (
        'highEmphasis': $cds-color-text-success-high-emphasis,
        'disabled': $cds-color-text-success-disabled
      ),
      'white': (
        'highEmphasis': $cds-color-text-white-high-emphasis,
        'mediumEmphasis': $cds-color-text-white-medium-emphasis,
        'disabled': $cds-color-text-white-disabled
      ),
      'link': (
        'neutral': (
          'resting': $cds-color-text-link-neutral-resting,
          'hover': $cds-color-text-link-neutral-hover,
          'active': $cds-color-text-link-neutral-active,
          'focused': $cds-color-text-link-neutral-focused,
          'visited': $cds-color-text-link-neutral-visited,
          'disabled': $cds-color-text-link-neutral-disabled
        ),
        'primary': (
          'resting': $cds-color-text-link-primary-resting,
          'hover': $cds-color-text-link-primary-hover,
          'active': $cds-color-text-link-primary-active,
          'focused': $cds-color-text-link-primary-focused,
          'visited': $cds-color-text-link-primary-visited,
          'disabled': $cds-color-text-link-primary-disabled
        )
      )
    )
  ),
  'effect': (
    'elevation': (
      'umbra': (
        '1': (
          'offset-x': $cds-effect-elevation-umbra-1-offset-x,
          'offset-y': $cds-effect-elevation-umbra-1-offset-y,
          'blur-radius': $cds-effect-elevation-umbra-1-blur-radius,
          'spread-radius': $cds-effect-elevation-umbra-1-spread-radius
        ),
        '2': (
          'offset-x': $cds-effect-elevation-umbra-2-offset-x,
          'offset-y': $cds-effect-elevation-umbra-2-offset-y,
          'blur-radius': $cds-effect-elevation-umbra-2-blur-radius,
          'spread-radius': $cds-effect-elevation-umbra-2-spread-radius
        ),
        '4': (
          'offset-x': $cds-effect-elevation-umbra-4-offset-x,
          'offset-y': $cds-effect-elevation-umbra-4-offset-y,
          'blur-radius': $cds-effect-elevation-umbra-4-blur-radius,
          'spread-radius': $cds-effect-elevation-umbra-4-spread-radius
        ),
        '6': (
          'offset-x': $cds-effect-elevation-umbra-6-offset-x,
          'offset-y': $cds-effect-elevation-umbra-6-offset-y,
          'blur-radius': $cds-effect-elevation-umbra-6-blur-radius,
          'spread-radius': $cds-effect-elevation-umbra-6-spread-radius
        ),
        '8': (
          'offset-x': $cds-effect-elevation-umbra-8-offset-x,
          'offset-y': $cds-effect-elevation-umbra-8-offset-y,
          'blur-radius': $cds-effect-elevation-umbra-8-blur-radius,
          'spread-radius': $cds-effect-elevation-umbra-8-spread-radius
        ),
        '12': (
          'offset-x': $cds-effect-elevation-umbra-12-offset-x,
          'offset-y': $cds-effect-elevation-umbra-12-offset-y,
          'blur-radius': $cds-effect-elevation-umbra-12-blur-radius,
          'spread-radius': $cds-effect-elevation-umbra-12-spread-radius
        ),
        '24': (
          'offset-x': $cds-effect-elevation-umbra-24-offset-x,
          'offset-y': $cds-effect-elevation-umbra-24-offset-y,
          'blur-radius': $cds-effect-elevation-umbra-24-blur-radius,
          'spread-radius': $cds-effect-elevation-umbra-24-spread-radius
        ),
        'color': $cds-effect-elevation-umbra-color
      ),
      'penumbra': (
        '1': (
          'offset-x': $cds-effect-elevation-penumbra-1-offset-x,
          'offset-y': $cds-effect-elevation-penumbra-1-offset-y,
          'blur-radius': $cds-effect-elevation-penumbra-1-blur-radius,
          'spread-radius': $cds-effect-elevation-penumbra-1-spread-radius
        ),
        '2': (
          'offset-x': $cds-effect-elevation-penumbra-2-offset-x,
          'offset-y': $cds-effect-elevation-penumbra-2-offset-y,
          'blur-radius': $cds-effect-elevation-penumbra-2-blur-radius,
          'spread-radius': $cds-effect-elevation-penumbra-2-spread-radius
        ),
        '4': (
          'offset-x': $cds-effect-elevation-penumbra-4-offset-x,
          'offset-y': $cds-effect-elevation-penumbra-4-offset-y,
          'blur-radius': $cds-effect-elevation-penumbra-4-blur-radius,
          'spread-radius': $cds-effect-elevation-penumbra-4-spread-radius
        ),
        '6': (
          'offset-x': $cds-effect-elevation-penumbra-6-offset-x,
          'offset-y': $cds-effect-elevation-penumbra-6-offset-y,
          'blur-radius': $cds-effect-elevation-penumbra-6-blur-radius,
          'spread-radius': $cds-effect-elevation-penumbra-6-spread-radius
        ),
        '8': (
          'offset-x': $cds-effect-elevation-penumbra-8-offset-x,
          'offset-y': $cds-effect-elevation-penumbra-8-offset-y,
          'blur-radius': $cds-effect-elevation-penumbra-8-blur-radius,
          'spread-radius': $cds-effect-elevation-penumbra-8-spread-radius
        ),
        '12': (
          'offset-x': $cds-effect-elevation-penumbra-12-offset-x,
          'offset-y': $cds-effect-elevation-penumbra-12-offset-y,
          'blur-radius': $cds-effect-elevation-penumbra-12-blur-radius,
          'spread-radius': $cds-effect-elevation-penumbra-12-spread-radius
        ),
        '24': (
          'offset-x': $cds-effect-elevation-penumbra-24-offset-x,
          'offset-y': $cds-effect-elevation-penumbra-24-offset-y,
          'blur-radius': $cds-effect-elevation-penumbra-24-blur-radius,
          'spread-radius': $cds-effect-elevation-penumbra-24-spread-radius
        ),
        'color': $cds-effect-elevation-penumbra-color
      ),
      'ambient': (
        '1': (
          'offset-x': $cds-effect-elevation-ambient-1-offset-x,
          'offset-y': $cds-effect-elevation-ambient-1-offset-y,
          'blur-radius': $cds-effect-elevation-ambient-1-blur-radius,
          'spread-radius': $cds-effect-elevation-ambient-1-spread-radius
        ),
        '2': (
          'offset-x': $cds-effect-elevation-ambient-2-offset-x,
          'offset-y': $cds-effect-elevation-ambient-2-offset-y,
          'blur-radius': $cds-effect-elevation-ambient-2-blur-radius,
          'spread-radius': $cds-effect-elevation-ambient-2-spread-radius
        ),
        '4': (
          'offset-x': $cds-effect-elevation-ambient-4-offset-x,
          'offset-y': $cds-effect-elevation-ambient-4-offset-y,
          'blur-radius': $cds-effect-elevation-ambient-4-blur-radius,
          'spread-radius': $cds-effect-elevation-ambient-4-spread-radius
        ),
        '6': (
          'offset-x': $cds-effect-elevation-ambient-6-offset-x,
          'offset-y': $cds-effect-elevation-ambient-6-offset-y,
          'blur-radius': $cds-effect-elevation-ambient-6-blur-radius,
          'spread-radius': $cds-effect-elevation-ambient-6-spread-radius
        ),
        '8': (
          'offset-x': $cds-effect-elevation-ambient-8-offset-x,
          'offset-y': $cds-effect-elevation-ambient-8-offset-y,
          'blur-radius': $cds-effect-elevation-ambient-8-blur-radius,
          'spread-radius': $cds-effect-elevation-ambient-8-spread-radius
        ),
        '12': (
          'offset-x': $cds-effect-elevation-ambient-12-offset-x,
          'offset-y': $cds-effect-elevation-ambient-12-offset-y,
          'blur-radius': $cds-effect-elevation-ambient-12-blur-radius,
          'spread-radius': $cds-effect-elevation-ambient-12-spread-radius
        ),
        '24': (
          'offset-x': $cds-effect-elevation-ambient-24-offset-x,
          'offset-y': $cds-effect-elevation-ambient-24-offset-y,
          'blur-radius': $cds-effect-elevation-ambient-24-blur-radius,
          'spread-radius': $cds-effect-elevation-ambient-24-spread-radius
        ),
        'color': $cds-effect-elevation-ambient-color
      )
    )
  ),
  'size': (
    'breakpoint': (
      'xs': $cds-size-breakpoint-xs,
      'sm': $cds-size-breakpoint-sm,
      'md': $cds-size-breakpoint-md,
      'lg': $cds-size-breakpoint-lg,
      'xl': $cds-size-breakpoint-xl
    ),
    'spacing': $cds-size-spacing,
    'radius': $cds-size-radius,
    'divider': $cds-size-divider,
    'border': (
      '_': $cds-size-border,
      'bold': $cds-size-border-bold
    ),
    'specific': (
      '_': $cds-size-specific,
      'xxs': $cds-size-specific-xxs,
      'xs': $cds-size-specific-xs,
      's': $cds-size-specific-s,
      'm': $cds-size-specific-m,
      'l': $cds-size-specific-l,
      'xl': $cds-size-specific-xl,
      'xxl': $cds-size-specific-xxl
    )
  ),
  'opacity': (
    'decorative': $cds-opacity-decorative,
    'disabled': $cds-opacity-disabled,
    'hexa': (
      '0': $cds-opacity-hexa-0,
      '1': $cds-opacity-hexa-1,
      '2': $cds-opacity-hexa-2,
      '3': $cds-opacity-hexa-3,
      '4': $cds-opacity-hexa-4,
      '5': $cds-opacity-hexa-5,
      '6': $cds-opacity-hexa-6,
      '7': $cds-opacity-hexa-7,
      '8': $cds-opacity-hexa-8,
      '9': $cds-opacity-hexa-9,
      '10': $cds-opacity-hexa-10,
      '11': $cds-opacity-hexa-11,
      '12': $cds-opacity-hexa-12,
      '13': $cds-opacity-hexa-13,
      '14': $cds-opacity-hexa-14,
      '15': $cds-opacity-hexa-15,
      '16': $cds-opacity-hexa-16,
      '17': $cds-opacity-hexa-17,
      '18': $cds-opacity-hexa-18,
      '19': $cds-opacity-hexa-19,
      '20': $cds-opacity-hexa-20,
      '21': $cds-opacity-hexa-21,
      '22': $cds-opacity-hexa-22,
      '23': $cds-opacity-hexa-23,
      '24': $cds-opacity-hexa-24,
      '25': $cds-opacity-hexa-25,
      '26': $cds-opacity-hexa-26,
      '27': $cds-opacity-hexa-27,
      '28': $cds-opacity-hexa-28,
      '29': $cds-opacity-hexa-29,
      '30': $cds-opacity-hexa-30,
      '31': $cds-opacity-hexa-31,
      '32': $cds-opacity-hexa-32,
      '33': $cds-opacity-hexa-33,
      '34': $cds-opacity-hexa-34,
      '35': $cds-opacity-hexa-35,
      '36': $cds-opacity-hexa-36,
      '37': $cds-opacity-hexa-37,
      '38': $cds-opacity-hexa-38,
      '39': $cds-opacity-hexa-39,
      '40': $cds-opacity-hexa-40,
      '41': $cds-opacity-hexa-41,
      '42': $cds-opacity-hexa-42,
      '43': $cds-opacity-hexa-43,
      '44': $cds-opacity-hexa-44,
      '45': $cds-opacity-hexa-45,
      '46': $cds-opacity-hexa-46,
      '47': $cds-opacity-hexa-47,
      '48': $cds-opacity-hexa-48,
      '49': $cds-opacity-hexa-49,
      '50': $cds-opacity-hexa-50,
      '51': $cds-opacity-hexa-51,
      '52': $cds-opacity-hexa-52,
      '53': $cds-opacity-hexa-53,
      '54': $cds-opacity-hexa-54,
      '55': $cds-opacity-hexa-55,
      '56': $cds-opacity-hexa-56,
      '57': $cds-opacity-hexa-57,
      '58': $cds-opacity-hexa-58,
      '59': $cds-opacity-hexa-59,
      '60': $cds-opacity-hexa-60,
      '61': $cds-opacity-hexa-61,
      '62': $cds-opacity-hexa-62,
      '63': $cds-opacity-hexa-63,
      '64': $cds-opacity-hexa-64,
      '65': $cds-opacity-hexa-65,
      '66': $cds-opacity-hexa-66,
      '67': $cds-opacity-hexa-67,
      '68': $cds-opacity-hexa-68,
      '69': $cds-opacity-hexa-69,
      '70': $cds-opacity-hexa-70,
      '71': $cds-opacity-hexa-71,
      '72': $cds-opacity-hexa-72,
      '73': $cds-opacity-hexa-73,
      '74': $cds-opacity-hexa-74,
      '75': $cds-opacity-hexa-75,
      '76': $cds-opacity-hexa-76,
      '77': $cds-opacity-hexa-77,
      '78': $cds-opacity-hexa-78,
      '79': $cds-opacity-hexa-79,
      '80': $cds-opacity-hexa-80,
      '81': $cds-opacity-hexa-81,
      '82': $cds-opacity-hexa-82,
      '83': $cds-opacity-hexa-83,
      '84': $cds-opacity-hexa-84,
      '85': $cds-opacity-hexa-85,
      '86': $cds-opacity-hexa-86,
      '87': $cds-opacity-hexa-87,
      '88': $cds-opacity-hexa-88,
      '89': $cds-opacity-hexa-89,
      '90': $cds-opacity-hexa-90,
      '91': $cds-opacity-hexa-91,
      '92': $cds-opacity-hexa-92,
      '93': $cds-opacity-hexa-93,
      '94': $cds-opacity-hexa-94,
      '95': $cds-opacity-hexa-95,
      '96': $cds-opacity-hexa-96,
      '97': $cds-opacity-hexa-97,
      '98': $cds-opacity-hexa-98,
      '99': $cds-opacity-hexa-99,
      '100': $cds-opacity-hexa-100
    ),
    'inactive': $cds-opacity-inactive
  ),
  'typography': (
    'headline-1': (
      'family': $cds-typography-headline-1-family,
      'font-size': $cds-typography-headline-1-font-size,
      'font-weight': $cds-typography-headline-1-font-weight,
      'line-height': $cds-typography-headline-1-line-height,
      'letter-spacing': $cds-typography-headline-1-letter-spacing
    ),
    'headline-2': (
      'family': $cds-typography-headline-2-family,
      'font-size': $cds-typography-headline-2-font-size,
      'font-weight': $cds-typography-headline-2-font-weight,
      'line-height': $cds-typography-headline-2-line-height,
      'letter-spacing': $cds-typography-headline-2-letter-spacing
    ),
    'headline-3': (
      'family': $cds-typography-headline-3-family,
      'font-size': $cds-typography-headline-3-font-size,
      'font-weight': $cds-typography-headline-3-font-weight,
      'line-height': $cds-typography-headline-3-line-height,
      'letter-spacing': $cds-typography-headline-3-letter-spacing
    ),
    'headline-4': (
      'family': $cds-typography-headline-4-family,
      'font-size': $cds-typography-headline-4-font-size,
      'font-weight': $cds-typography-headline-4-font-weight,
      'line-height': $cds-typography-headline-4-line-height,
      'letter-spacing': $cds-typography-headline-4-letter-spacing
    ),
    'headline-5': (
      'family': $cds-typography-headline-5-family,
      'font-size': $cds-typography-headline-5-font-size,
      'font-weight': $cds-typography-headline-5-font-weight,
      'line-height': $cds-typography-headline-5-line-height,
      'letter-spacing': $cds-typography-headline-5-letter-spacing
    ),
    'headline-6': (
      'family': $cds-typography-headline-6-family,
      'font-size': $cds-typography-headline-6-font-size,
      'font-weight': $cds-typography-headline-6-font-weight,
      'line-height': $cds-typography-headline-6-line-height,
      'letter-spacing': $cds-typography-headline-6-letter-spacing
    ),
    'subtitle-1': (
      'family': $cds-typography-subtitle-1-family,
      'font-size': $cds-typography-subtitle-1-font-size,
      'font-weight': $cds-typography-subtitle-1-font-weight,
      'line-height': $cds-typography-subtitle-1-line-height,
      'letter-spacing': $cds-typography-subtitle-1-letter-spacing
    ),
    'subtitle-2': (
      'family': $cds-typography-subtitle-2-family,
      'font-size': $cds-typography-subtitle-2-font-size,
      'font-weight': $cds-typography-subtitle-2-font-weight,
      'line-height': $cds-typography-subtitle-2-line-height,
      'letter-spacing': $cds-typography-subtitle-2-letter-spacing
    ),
    'body-1': (
      'family': $cds-typography-body-1-family,
      'font-size': $cds-typography-body-1-font-size,
      'font-weight': $cds-typography-body-1-font-weight,
      'line-height': $cds-typography-body-1-line-height,
      'letter-spacing': $cds-typography-body-1-letter-spacing
    ),
    'body-2': (
      'family': $cds-typography-body-2-family,
      'font-size': $cds-typography-body-2-font-size,
      'font-weight': $cds-typography-body-2-font-weight,
      'line-height': $cds-typography-body-2-line-height,
      'letter-spacing': $cds-typography-body-2-letter-spacing
    ),
    'label': (
      'family': $cds-typography-label-family,
      'font-size': $cds-typography-label-font-size,
      'font-weight': $cds-typography-label-font-weight,
      'line-height': $cds-typography-label-line-height,
      'letter-spacing': $cds-typography-label-letter-spacing
    ),
    'caption': (
      'family': $cds-typography-caption-family,
      'font-size': $cds-typography-caption-font-size,
      'font-weight': $cds-typography-caption-font-weight,
      'line-height': $cds-typography-caption-line-height,
      'letter-spacing': $cds-typography-caption-letter-spacing
    ),
    'overline': (
      'family': $cds-typography-overline-family,
      'font-size': $cds-typography-overline-font-size,
      'font-weight': $cds-typography-overline-font-weight,
      'line-height': $cds-typography-overline-line-height,
      'letter-spacing': $cds-typography-overline-letter-spacing
    ),
    'button': (
      'family': $cds-typography-button-family,
      'font-size': $cds-typography-button-font-size,
      'font-weight': $cds-typography-button-font-weight,
      'line-height': $cds-typography-button-line-height,
      'letter-spacing': $cds-typography-button-letter-spacing
    )
  )
);

