.d-flex {
	display: flex !important;
}

.flex-direction-row {
	flex-direction: row !important;
}

.flex-direction-row-reverse {
	flex-direction: row-reverse !important;
}

.flex-direction-column {
	flex-direction: column !important;
}

.justify-content-space-between {
	justify-content: space-between !important;
}

.justify-content-center {
	justify-content: center !important;
}

.align-items-center {
	align-items: center !important;
}

.align-self-center {
	align-self: center !important;
}

@for $i from 1 to 12 {
	.flex-#{$i} {
		flex: $i;
	}

	.gap-#{$i} {
		gap: $i * 5px;
	}
}