selectpicker {
	mat-select {
		&.mat-mdc-select-disabled {
			opacity: 0.65 !important;

			.mat-mdc-select-trigger {
				cursor: not-allowed !important;
			}
		}

		.mat-mdc-select-trigger {
			.mat-mdc-select-value {
				text-align: left;
			}
		}

		.mat-mdc-select-placeholder {
			color: inherit;
		}

		.mat-mdc-select-arrow-wrapper {
			&:before {
				position: absolute;
				top: 0;
				right: 0;
				content: '\e5cf';
				font-family: 'Material Icons Round';
				background-color: transparent;
				font-size: 2em;
			}

			.mat-mdc-select-arrow {
				svg {
					visibility: hidden;
				}
			}
		}
	}
}

.selectpicker-panel {
	border-radius: 6px !important;
	min-width: 160px;
	max-height: 350px !important;

	mat-option {
		padding: 4px 12px;
		min-height: 0px !important;

		span {
			display: block !important;
			overflow: hidden;
			text-overflow: ellipsis;
			color: $cds-color-text-neutral-medium-emphasis !important;
			@include typography(body-1,!important);
			font-size: $n-label-input-font-size !important;
		}

		.mat-mdc-option-ripple {
			display: none;
		}

		.mat-pseudo-checkbox-checked, .mat-pseudo-checkbox-indeterminate {
			&.mat-pseudo-checkbox-minimal:after {
				color: $cds-color-action-neutral-active !important;
			}
		}
	}
}

.cdk-overlay-container {
	z-index: 1060 !important;
}