app-menu aside {
	position: fixed;
	box-shadow: 0px 0px 10px rgba($cds-color-border-neutral-decorative,0.3);
	top: 64px;
	height: calc(100% - 65px);
	transform: translate3d(-250px,0,0);
	z-index: 9;
	width: 300px;
	overflow: hidden;
	opacity: 0;
	transition: all;
	transition-duration: 300ms;
	background: transparent;
	border-right: 1px solid $cds-color-border-neutral-decorative;

	&.toggled {
		transform: translate3d(0,0,0);
		opacity: 1;
	}

	.menu-inner {
		background-color: $cds-color-background-white-main;
		height: 100%;
		width: 300px;
		display: flex;
		flex-direction: column;
		overflow-y: auto;
		padding-top: 20px;

		ul.main-menu {
			margin-bottom: 100px;
			display: flex;
			flex-direction: column;
			list-style: none;
			padding-left: 0;

			& > li {
				& > a {
					display: flex;
					flex-direction: row;
					justify-content: flex-end;
					color: $cds-color-background-neutral-main;
					@include typography(body-2);
					padding: 10px;
					align-items: center;

					&:hover {
						color: var(--theme-primary-700);
						background-color: rgba(var(--theme-primary-700_rgb),0.16);
					}

					& > div {
						display: flex;
						flex-direction: row;
						width: 100%;

						& > icon {
							display: flex;

							& > i {
								font-size: 20px;
								line-height: 28px;
							}
						}

						& > span {
							padding-left: 10px;
							line-height: 28px;
						}
					}

					& > icon {
						& > i {
							font-size: 20px;
						}
					}
				}

				& > ul {
					list-style: none;
					padding-left: 0px;

					& > li {
						& > a {
							color: $cds-color-background-neutral-main;
							@include typography(body-2);

							& > div {
								display: flex;
								flex-direction: row;
								justify-content: flex-end;
								padding: 10px;

								&:hover {
									color: var(--theme-primary-700);
									background-color: rgba(var(--theme-primary-700_rgb),0.16);
								}

								& > span {
									padding-left: 26px;
									display: flex;
									flex-direction: column;
									justify-content: center;
									width: 100%;
								}

								& > icon {
									display: flex;
									flex-direction: column;
									justify-content: center;
									width: 25px;

									& > i {
										font-size: 20px;
									}
								}
							}
						}

						& > ul {
							list-style: none;
							border: 1px solid $cds-color-border-neutral-decorative;
							padding-left: 0px;
							background-color: $cds-color-background-neutral-light;

							& > li {
								& > a {
									color: $cds-color-background-neutral-main;
									@include typography(body-2);

									& > div {
										display: flex;
										flex-direction: row;
										padding: 10px;

										&:hover {
											color: var(--theme-primary-700);
											background-color: rgba(var(--theme-primary-700_rgb),0.16);
										}

										& > span {
											padding-left: 26px;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}