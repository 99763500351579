.planning {
	.lv-header-alt {
		left: 0;
		right: 0;
		width: auto !important;

		@media (min-width: $cds-size-breakpoint-lg) {
			padding-left: 85px;
			padding-right: 85px;
		}
	}

	.show-more,.link {
		color: var(--theme-primary-700);
		font-weight: bold !important;
		cursor: pointer;

		.fc-datagrid-expander .fc-icon {
			display: none !important;
		}
	}

	.spinner {
		text-align: center;

		.fc-datagrid-expander .fc-icon {
			display: none !important;
		}
	}

	.loading {
		[n-planning],full-calendar {
			.fc-view-harness {
				&:before {
					content: ' ';
					position: absolute;
					width: 100%;
					height: 100%;
					background-color: rgba(255,255,255,.4);
					z-index: 2;
				}
			}
		}
	}

	.resource-area-header {
		.fc-datagrid-cell-cushion.fc-scrollgrid-sync-inner {
			width: 95%;
		}
	}

	.fc-resource-timeline-divider {
		pointer-events: none;
	}

	.fc-timeline-now-indicator-arrow {
		display: none;
	}

	.fc-icon.fc-icon-material-icons-round {
		font-family: 'Material Icons Round' !important;
	}

	.fc-datagrid-cell-cushion {
		text-overflow: ellipsis;
	}
}

body.menu-opened {
	.planning {
		.lv-header-alt {
			padding-left: 380px;
		}
	}
}
