.separator {
	position: relative;
	text-align: center;
	padding: 16px 0px;

	&:not(.separator-text) {
		span {
			display: inline-block;
			width: 35px;
			height: 35px;
			border-radius: 50%;
			background: $cds-color-extended-grey-40;
			line-height: 35px;
			vertical-align: middle;
			color: white;
		}

		span:before,span:after {
			position: absolute;
			content: ' ';
			width: calc(50% - 25px);
			height: 1px;
			top: 50%;
			border: 1px solid $cds-color-extended-grey-40;
		}

		span:before {
			left: 0;
		}

		span:after {
			right: 0;
		}
	}

	&.separator-text {
		font-size: 1.2em;
		font-weight: bold;

		span:before,span:after {
			display: inline-block;
			content: ' ';
			width: 15%;
			border-top: 2px solid $cds-color-extended-grey-40;
			margin: 0 15px;
			padding-top: 4px;
		}
	}
}