.datepicker {
	thead {
		background-color: var(--theme-primary-700);
	}
}

.bootstrap-datetimepicker-widget {
	width: 280px !important;

	table th, table td {
		border-radius: 0;

		span {
			border-radius: 0;
		}

		&:hover {
			background: transparent;
		}
	}

	table thead tr:first-child th:hover {
		background: transparent;
	}

	table {
		border-collapse: separate;

		td.day {
			height: 30px;
			line-height: 20px;
			border-radius: 50%;

			&.disabled {
				border-radius: 0;
				background-color: rgba(0,0,0,0.1);
			}

			&.today {
				border: 1px solid var(--theme-primary-700);

				&:before {
					display: none;
				}
			}

			&.active {
				background-color: rgba(var(--theme-primary-700_rgb),.2);
				color: inherit;

				&.today {
					border: 1px solid transparent;
				}
			}
		}

		span.month {
			&.active {
				background-color: rgba(var(--theme-primary-700_rgb),.2);
				color: inherit;
			}
		}

		span.year {
			&.active {
				background-color: rgba(var(--theme-primary-700_rgb),.2);
				color: inherit;
			}
		}
	}

	table td span.active {
		background-color: rgba(var(--theme-primary-700_rgb),.2);
		color: inherit;
	}

	table thead {
		color: white;

		.glyphicon {
			color: white;
		}
	}

	.datepicker-days table {
		margin-bottom: 5px;
	}

	table tbody .glyphicon {
		font-size: 2em;
	}

	.list-unstyled {
		a {
			width: 100%;
			padding: 0;
			margin: 0;

			&:hover {
				span.glyphicon {
					background-color: rgba(0,0,0,0.1);
				}
			}
		}

		span.glyphicon {
			line-height: 100%;
			height: 100%;
			margin: auto;
			padding: 5px;
			padding-bottom: 4px;
		}
	}
}

.tempus-dominus-widget.light {
	width: 280px !important;
	padding: 4px !important;

	&.timepicker-sbs {
		width: 560px !important;
	}

	&.inline {
		box-shadow: none;
	}

	.calendar-header {
		background-color: var(--theme-primary-700);
		color: white !important;
		margin-bottom: 0px !important;

		.picker-switch {
			align-items: center;
			display: flex;
			justify-content: center;
		}
	}

	.date-container-decades {
		grid-auto-rows: 40px;

		div:not(.no-highlight).active {
			background-color: var(--theme-primary-700) !important;
		}
	}

	.date-container-years {
		grid-auto-rows: 40px;

		div:not(.no-highlight).active {
			background-color: var(--theme-primary-700) !important;
		}
	}

	.date-container-months {
		grid-auto-rows: 40px;

		div:not(.no-highlight).active {
			background-color: var(--theme-primary-700) !important;
		}
	}

	.date-container-days {
		.dow {
			background-color: var(--theme-primary-700);
			color: white !important;
			align-items: center;
			display: flex;
		}

		div:not(.no-highlight) {
			&.active {
				background-color: var(--theme-primary-700) !important;
			}

			&.today {
				&:before {
					border-bottom-color: var(--theme-primary-700) !important;
				}
			}
		}
	}

	.time-container {
		display: flex !important;
		align-items: center;
		justify-content: center;

		.time-container-clock {
			grid-auto-rows: 40px;
			grid-auto-columns: 40px;
		}

		.time-container-hour {
			grid-auto-rows: 40px;
			grid-auto-columns: 40px;
		}

		.time-container-minute {
			grid-auto-rows: 40px;
			grid-auto-columns: 40px;
		}
	}

	div[data-action] {
		i {
			font-size: x-large;
			color: $cds-color-action-neutral-active;
		}
	}
}