.autocomplete {
	&.modal-body {
		.lv-item {
			padding: 5px;

			.tile-inner {
				margin: 0;
			}
		}
	}
}

autocomplete {
	.input-group {
		color: $cds-color-text-neutral-medium-emphasis !important;
		@include typography(body-1);

		border: 1px solid $cds-color-border-neutral-decorative;
		border-radius: 6px;
		padding-left: 8px;
		padding-top: 1px;
		padding-bottom: 1px;

		&:hover {
			border: 1px solid $cds-color-border-neutral-high-emphasis;
			background-color: rgba($cds-color-border-neutral-high-emphasis,.06);
		}

		.form-control {
			border: none;
			background-color: transparent;
			height: 30px;

			&:hover {
				border: none;
				background-color: transparent;
			}

			&:focus {
				border: none;
				background-color: transparent;
			}
		}

		.input-group-addon {
			border: none !important;

			.zmdi, .material-icons-round {
				top: 1px;
			}
		}
	}

	&.focused {
		.input-group {
			border: 2px solid var(--theme-primary-700);
			padding-left: 7px;
			padding-top: 0;
			padding-bottom: 0;
			background-color: transparent;

			.form-control {
				border: none;
				background-color: transparent;
			}
		}
	}

	ul.dropdown-menu {
		a.add-item {
			background-color: rgba(var(--theme-primary-700_rgb),0.5) !important;
		}
	}
}