.modal-dialog .attachment, .attachment-inline {
	.alert {
		color: $cds-color-action-neutral-inactive;

		i {
			font-size: 2em;
		}
	}
}

.modal-dialog, .attachment-inline {
	.attachment {
		&:not(.nv-file-over) {
			div.alert {
				border: 2px dashed $cds-color-border-neutral-decorative;

				.has-file {
					display: none;
				}

				&:hover {
					background-color: $cds-color-background-neutral-light;
				}
			}
		}

		&.nv-file-over {
			div.alert {
				border: 2px solid;
				color: $cds-color-background-success-dark;
				background-color: $cds-color-background-success-light;
			}
		}

		.thumb {
			canvas {
				max-width: 100%;
			}
		}

		.thumbnail {
			position: relative;
			border-radius: 6px;
			border-color: $cds-color-border-neutral-decorative;

			.content {
				height: 250px;
				text-align: center;

				@media (min-width: 768px) {
					height: 100px;
				}

				img {
					max-width: 100%;
					max-height: 250px;

					@media (min-width: 768px) {
						max-height: 100px;
					}
				}

				div {
					span {
						font-size: 5em;

						small {
							display: block;
						}
					}
				}
			}

			.caption {
				height: 36px;
			}

			.title {
				height: 20px;
				overflow: hidden;
			}

			.alerte {
				position: absolute;
				right: 10px;
				top: 0;
				padding: 0;
				font-size: 2em;
			}

			div.top-actions {
				position: absolute;
				padding-left: 10px;
				padding-right: 10px;
				z-index: 1;

				mat-icon {
					cursor: pointer;
				}

				> icon > i, > i {
					font-size: 1.6em;
				}
			}

			div.selector {
				position: absolute;
				display: none;
				top: 50%;
				width: 100%;
				margin-top: -30px;
				text-align: center;
				z-index: 1;

				.btn-group {
					.btn {
						background-color: rgba(255,255,255,.9);
					}
				}
			}

			&:hover {
				div.selector {
					display: block;
				}
			}

			&.uploading {
				&:hover {
					background-color: transparent;
				}

				div.content {
					opacity: 0.5;
				}
			}

			&.infected {
				border: 1px solid $cds-color-background-critical-dark;

				i.alert {
					color: $cds-color-background-critical-dark;
				}
			}

			&.unsafe {
				border: 1px solid $cds-color-background-warn-dark;

				i.alert {
					color: $cds-color-background-warn-dark;
				}
			}
		}

		.liste {
			.thumb {
				i {
					font-size: 2em;
				}

				small {
					display: block;
				}
			}

			.uploading {
				.thumb {
					opacity: 0.5;
				}

				.lv-attrs {
					li {
						width: "calc(100% - 50px)";
					}
				}
			}

			.infected {
				color: $cds-color-background-critical-dark;
			}

			.unsafe {
				color: $cds-color-background-warn-dark;
			}
		}
	}
}

.attachment-inline {
	.switcher {
		position: relative;

		.switcher-inner {
			> .attachment {
				> .content {
					height: 300px;
					background-size: cover;
					background-position: center;
					background-repeat: no-repeat;
					display: none;
				}

				.thumb {
					text-align: center;
				}

				&.active {
					> .content {
						display: block;
					}
				}
			}

			.progress {
				position: absolute;
				width: 100%;
				bottom: 0;
				left: 0;
			}

			i {
				font-size: 1.2em;
			}
		}
	}

	input[type = "file"] {
		display: none;
	}

	.navigation {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40px;
		text-align: center;
		height: 100%;
		top: 0;
		opacity: 0.3;
		cursor: pointer;
		transition: opacity 500ms;

		&.hidden {
			display: none;
		}

		&.left {
			left: 10px;
		}

		&.right {
			right: 10px;
		}

		&:after {
			content: ' ';
			display: table;
		}

		&:hover {
			opacity: 1.0;
		}

		i {
			display: table-cell;
			vertical-align: middle;
		}
	}

	.description {
		text-align: left;
		margin-bottom: 0;
		border: 2px dashed $cds-color-border-neutral-decorative;

		&:hover {
			background-color: $cds-color-background-neutral-light;
		}

		strong {
			font-weight: bold;
		}
	}

	.btn-container {
		display: none;
		position: absolute;
		bottom: 30px;
		text-align: center;
		width: 100%;

		.btn-group {
			background-color: rgba(255,255,255,0.9);

			a {
				pointer-events: all;
				cursor: pointer;
				opacity: 1;
			}
		}
	}

	&:hover {
		.btn-container {
			display: block;
		}
	}
}
